import React, { useState, useEffect, useContext, useRef } from 'react';
import DataTable from '../components/DataTableComponents';
import IconUI from '../components/iu/icon_ui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField, Autocomplete, MenuItem, Link, Menu, FormGroup, FormLabel } from '@mui/material';
import { AiOutlineSelect, AiOutlineDelete, AiOutlineSearch } from 'react-icons/ai';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';
import { BsCircleFill, BsCheck2Circle } from "react-icons/bs";
import { GlobalContext } from '../context/global_context';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import { RiFileExcel2Line } from "react-icons/ri";
import { onlyNumber } from '../utils/inputsRules';





const Peticiones = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 600,
        borderRadius: "40px",
    };
    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 'auto',
        borderRadius: "40px",
    };
    const style3 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "90%",
        borderRadius: "40px",
    };
    const style4 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 600,
        borderRadius: "40px",
    };
    const style5 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 'auto',
        borderRadius: "40px",
    };
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const { showNotification } = useContext(NotificacionContext)

    // state del componente
    const [state, setState] = useState({
        peticiones: [],
        dependencias: [],
        tiposPeticion: [],
        detalles: [],
        adjuntos: [],
        openPeticion: false,
        openEliminar: false,
        openDetalles: false,
        openImprimir: false,
        openAdjuntos: false,
        openDocumento: false,
        openDescripcion: false,
        peticionHistorico: [],
        openBitacora: false,
        ageFilterChecked: true,
        peticionSeleccionada: {},
        documento: null,
        urlFrame: ''
    });

    const [formulario, setFormulario] = useState({
        openFiltro: false,
        municipios: [],
        localidades: [],
        cargos: [],
        titulos: [],

        ciudadano: null,
        apellido_paterno: null,
        apellido_materno: null,
        calle: null,
        colonia: null,
        cp: null,
        telefono: null,
        municipio_id: null,
        localidad_id: null,
        tipo_titulo_id: null,
        tipo_cargo_id: null,
        folio: null,
        atencion_inmediata: null,
        tipo_peticion_id: null,
        peticion: null,
        dependencia_id: null,
        tipo_gobierno: null,
        estatus_peticion_id: null,
        respuesta: null,
        observaciones: null,
        numero_gira: null,
        fecha_inicio: null,
        fecha_fin: null,
        edad_inicio: null,
        edad_fin: null,
        bandeja: 1
    });

    //palabra de inicio
    const { filtro, filtro2, acuse, filtroLeida, dashboard, filtro3 } = useContext(GlobalContext)


    const fileInputRef = useRef(null);

    //modificar petición

    const [errors, setErrors] = useState({});

    const openModalPeticion = async () => {
        setLoader({ ...loader, loading: true })
        const tiposPeticion = await services({ method: "GET", service: "catTipoPeticiones/listarPeticiones", body: null });
        const dependencias = await services({ method: "GET", service: "dependencias/listar", body: null });
        if (tiposPeticion.status === 200 && dependencias.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openPeticion: true, peticionSeleccionada: selectedItemId, tiposPeticion: tiposPeticion.data.data, dependencias: dependencias.data.data });
            handleMenuClose()
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Ocurrió algo al cargar la información', null, 4);
            handleMenuClose()
        }
    }

    const openModalEliminar = () => {
        setState({ ...state, openEliminar: true, peticionSeleccionada: selectedItemId });
        handleMenuClose()
    }

    const openModalBitacora = async () => {
        setLoader({ ...loader, loading: true })
        const formData = new FormData();
        formData.append("id", selectedItemId.id);
        let response = await services({
            method: 'POST', service: `historicoPeticiones/listarCambios`, body: formData
        });
        if (response.status === 200) {
            setLoader({ ...loader, loading: false })
            console.log(response.data)
            setState({ ...state, peticionHistorico: response.data.data, openBitacora: true });
            handleMenuClose()
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', response.data.message, null, 4);
            handleMenuClose()
        }

    }

    const openModalDetalles = async () => {
        setLoader({ ...loader, loading: true })
        let detalles = await services({ method: 'POST', service: `peticiones/mostrarPeticion`, body: { id: selectedItemId.id } });
        if (detalles.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, detalles: detalles.data.data, openDetalles: true });
            handleMenuClose()
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'ups... Algo sucedió', 'Error al obtener información', null, 4);
            handleMenuClose()
        }

    }

    const openModalDescripcion = (tipo, data) => {
        setState({ ...state, openDescripcion: true, descripcion: tipo + " " + data });
    }

    const openModalImprimir = () => {
        setState({ ...state, openImprimir: true, peticionSeleccionada: selectedItemId.id });
        handleMenuClose()
    }

    const openModalAdjuntos = async () => {
        setLoader({ ...loader, loading: true })
        let listaAdjuntos = await services({ method: 'POST', service: `documentos/listarAdjuntos`, body: { id: selectedItemId.id } });
        if (listaAdjuntos.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openAdjuntos: true, adjuntos: listaAdjuntos.data.data });
            handleMenuClose()
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...Algo Sucedió', 'No se lograron cargar los archivos adjuntos', null, 4);
        }
    }

    const openModalFiltro = async () => {
        setLoader({ ...loader, loading: true })
        let municipios = await services({ method: 'GET', service: `catMunicipios/listar`, body: null })
        let cargos = await services({ method: 'GET', service: `catTipoCargos/listarCargos`, body: null });
        let titulos = await services({ method: 'GET', service: `catTiposTitulos/listar`, body: null });
        let tiposPeticion = await services({
            method: "GET", service: "catTipoPeticiones/listarPeticiones",
            body: null
        });
        let dependencias = await services({ method: "GET", service: "dependencias/listar", body: null })

        if (municipios.status === 200 && cargos.status === 200 && titulos.status === 200 && tiposPeticion.status === 200 && dependencias.status === 200) {
            setLoader({ ...loader, loading: false })
            setFormulario({ ...formulario, municipios: municipios.data.data, cargos: cargos.data.data, titulos: titulos.data.data, openFiltro: true });
            setState({ ...state, tiposPeticion: tiposPeticion.data.data, dependencias: dependencias.data.data })
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups... algo sucedió', 'Error al obtener información', null, 4);
        }

    }

    const handleCloseFiltro = () => {
        setFormulario({ ...formulario, openFiltro: false })
    }

    const handleTipoPeticiones = (e) => {
        setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, tipo_peticion_id: e ? e.id : null } })
    }

    const handleDependencias = (e) => {
        setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, dependencia_id: e ? e.id : null } })
    }

    const handlePrioridad = (e) => {
        setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, atencion_inmediata: e.target.value } })
    }

    const handleStatusPeticion = (e) => {
        setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, estatus_peticion_id: e.target.value, respuesta: '' } })
    }

    const handleDocumento = (e) => {
        setState({ ...state, documento: e.target.value })
    }

    //manejo inputs filtro

    const handleMunicipio = async (e) => {
        if (e === null) {
            setFormulario((formulario) => ({ ...formulario, municipio: '', localidad: '' }))
        } else {
            let localidades = await services({ method: 'POST', service: `catLocalidades/listaPorMunicipio`, body: { municipio_id: e.id } });
            if (localidades.status === 200) {
                setFormulario((formulario) => ({ ...formulario, municipio_id: e ? e.id : '', localidades: localidades.data.data, localidad_id: '' }))
            } else {
                showNotification('error', 'Ups...', localidades.data.message, null, 4)
            }
        }
    }
    const handleLocalidad = (e) => {
        setFormulario({ ...formulario, localidad_id: e.target.value })
    }
    const handleCargo = (e) => {
        setFormulario((formularioState) => ({ ...formularioState, tipo_cargo_id: e ? e.id : null }))
    }
    const handleTitulo = (e) => {
        setFormulario({ ...formulario, tipo_titulo_id: e.target.value })
    }
    const handleTipoPeticionesFiltro = (e) => {
        setFormulario({ ...formulario, tipo_peticion_id: e ? e.id : null })
    }
    const handleDependenciasFiltro = (e) => {
        setFormulario((formulario) => ({ ...formulario, dependencia_id: e ? e.id : null, }));
    }
    const handleTipoGobierno = (e) => {
        setFormulario({ ...formulario, tipo_gobierno: e.target.value })
    }
    const handleInicio = (e) => {
        const selectedDate = dayjs(e.$d);
        setFormulario({ ...formulario, fecha_inicio: selectedDate.format('YYYY-MM-DD') })
    }
    const handleFin = (e) => {
        const selectedDate = dayjs(e.$d);
        setFormulario({ ...formulario, fecha_fin: selectedDate.format('YYYY-MM-DD') })
    }

    //fin manejo inputs filtro


    //state del loading
    const [loader, setLoader] = useState({
        loading: true
    });

    const handleClosePeticion = () => {
        setState({ ...state, openPeticion: false });
        setErrors({});
    }

    const handleCloseEliminar = () => {
        setState({ ...state, openEliminar: false });
    }
    const handleCloseBitacora = () => {
        setState({ ...state, openBitacora: false });
    }

    const handleCloseDetalles = () => {
        setState({ ...state, openDetalles: false });
    }

    const handleCloseDescripcion = () => {
        setState({ ...state, openDescripcion: false });
    }

    const handleCloseImprimir = () => {
        setState({ ...state, openImprimir: false });
    }

    const handleCloseDocumento = () => {
        setState({ ...state, openDocumento: false });
    }

    const handleCloseAdjuntos = () => {
        setState({ ...state, openAdjuntos: false });
        setSelectedFile(null)
    }

    const handleAcuse = async (id) => {
        setLoader({ ...loader, loading: true })
        let response = await services({ method: 'POST', service: `peticiones/generarAcuse`, body: { id: id } });
        if (response.status === 200) {
            let peticiones = await services({ method: 'POST', service: `peticiones/listarPeticiones`, body: formulario });
            if (peticiones.status === 200) {
                setState({ ...state, peticiones: peticiones.data.data })
                setLoader({ ...loader, loading: false })
                showNotification('success', 'Éxito', response.data.message, null, 4);
                handleMenuClose()
            } else {
                setLoader({ ...loader, loading: false })
                showNotification('error', 'ups... Algo sucedió', 'Error al obtener información', null, 4);
                handleMenuClose()
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'ups... Algo sucedió', response.data.message, null, 4);
            handleMenuClose()
        }

    }

    const handleVerAcuse = (data) => {
        setState({ ...state, peticionSeleccionada: data });
        descargarDocumentos(5);
        handleMenuClose();
    }

    // manejo de archivos
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    // manejo de archivos

    //Botón de acciones

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const handleMenuClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setSelectedItemId(data);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    //Botón de acciones


    //columnas y lógica de la tabla
    const columns = [
        {
            name: "leida",
            label: "LEÍDAS",
            options: {
                filter: true,
                sort: true,
                display: false,
                filterList: filtroLeida !== '' ? [filtroLeida] : [],
                customFilterListOptions: { render: v => v ? `${v}` : '' },
                customBodyRenderLite: (dataIndex) => {
                    let leida = state.peticiones[dataIndex].leida;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>
                        {leida}
                    </div>)
                }
            }
        },
        {
            name: "semaforo",
            label: ".",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let semaforo = state.peticiones[dataIndex].semaforo;
                    if (semaforo === 'ATENDIDA') {
                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'green'}><BsCheck2Circle /></IconUI>
                        </div>)
                    } else if (semaforo === 'VERDE') {

                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'green'}><BsCircleFill /></IconUI>
                        </div>)
                    } else if (semaforo === 'AMARILLO') {
                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'yellow'}><BsCircleFill /></IconUI>
                        </div>)
                    } else {
                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'red'}><BsCircleFill /></IconUI>
                        </div>)
                    }


                }
            }
        },
        {
            name: "acuse",
            label: "ACUSE",
            options: {
                filter: true,
                sort: true,
                display: false,
                filterList: acuse !== '' ? [acuse] : [],
                customFilterListOptions: { render: v => v ? `${v}` : '' },
                customBodyRenderLite: (dataIndex) => {
                    let acuse = state.peticiones[dataIndex].acuse;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>
                        {acuse}
                    </div>)
                }
            }
        },
        {
            name: "folio",
            label: "FOLIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let folio = state.peticiones[dataIndex].folio;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>
                        {folio}
                    </div>)
                }
            }
        },
        {
            name: "fecha_creacion",
            label: "FECHA",
            options: {
                filter: true,
                sort: false,
                display: false,
                customBodyRenderLite: (dataIndex) => {
                    let alta = moment(state.peticiones[dataIndex].fecha_creacion, 'DD-MM-YYYY');
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}> {alta.format('DD-MM-YYYY')} </div>)
                }
            }
        },
        {
            name: "fecha_creacion",
            label: "FECHA",
            options: {
                filter: true,
                sort: false,
                download: false,

                customBodyRenderLite: (dataIndex) => {
                    let alta = moment(state.peticiones[dataIndex].fecha_creacion, 'DD-MM-YYYY');
                    // le meti mano carnal XD, pues no la metiste bien xD 
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}> {alta.format('DD-MM-YYYY')} </div>)
                },
                filterType: 'custom',

                // if the below value is set, these values will be used every time the table is rendered.
                // it's best to let the table internally manage the filterList
                //filterList: [25, 50],

                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1] && state.ageFilterChecked) {
                            return [`FECHA INICIO: ${v[0]}`, `FECHA FINAL: ${v[1]}`];
                        } else if (v[0] && v[1] && !state.ageFilterChecked) {
                            return `FECHA INICIO: ${v[0]}, FECHA FINAL: ${v[1]}`;
                        } else if (v[0]) {
                            return `FECHA INICIO: ${v[0]}`;
                        } else if (v[1]) {
                            return `FECHA FINAL: ${v[1]}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {

                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(fecha_creacion, filters) {
                        if (filters[0] && filters[1]) {
                            return dayjs(fecha_creacion).format('YYYY-MM-DD') < filters[0] || dayjs(fecha_creacion).format('YYYY-MM-DD') > filters[1];
                        } else if (filters[0]) {
                            return dayjs(fecha_creacion).format('YYYY-MM-DD') < filters[0];
                        } else if (filters[1]) {
                            return dayjs(fecha_creacion).format('YYYY-MM-DD') > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel className='mb-2' style={{ fontSize: '12px' }}>RANGO DE FECHAS</FormLabel>
                            <FormGroup row>

                                <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="INICIO"
                                        slotProps={{ textField: { size: 'small' } }}
                                        value={filterList[index][0] ? dayjs(filterList[index][0]) : null}
                                        format="DD/MM/YYYY"
                                        views={['year', 'month', 'day']}
                                        onChange={event => {
                                            filterList[index][0] = dayjs(event).format('YYYY-MM-DD');
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '40%' }}
                                    />
                                </LocalizationProvider>

                                <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="FINAL"
                                        slotProps={{ textField: { size: 'small' } }}
                                        value={filterList[index][1] ? dayjs(filterList[index][1]) : null}
                                        format="DD/MM/YYYY"
                                        views={['year', 'month', 'day']}
                                        onChange={event => {
                                            filterList[index][1] = dayjs(event).format('YYYY-MM-DD');
                                            onChange(filterList[index], index, column);
                                        }}
                                        style={{ width: '40%', marginLeft: '10px' }}
                                    />
                                </LocalizationProvider>
                            </FormGroup>
                        </div>
                    ),
                }
            }
        },
        {
            name: "ciudadano",
            label: "NOMBRE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.peticiones[dataIndex].ciudadano;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "municipio",
            label: "MUNICIPIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let municipio = state.peticiones[dataIndex].municipio;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{municipio}</div>)
                }
            }
        },
        {
            name: "peticion",
            label: "PETICIÓN",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let descripcion = state.peticiones[dataIndex].peticion;
                    let tipo = state.peticiones[dataIndex].tipo_peticion;
                    return (
                        <div style={{ height: '60px' }} className="d-flex align-items-center cursor-pointer " onClick={() => {
                            openModalDescripcion(state.peticiones[dataIndex].tipo_peticion, state.peticiones[dataIndex].peticion);
                        }}>
                            {tipo ? tipo + " " + descripcion.substring(0, 30) : " " + descripcion.substring(0, 30)} ...
                        </div>
                    )
                }
            }
        },
        {
            name: "dependencia",
            label: "DEPENDENCIA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let dependencia = state.peticiones[dataIndex].dependencia;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{dependencia}</div>)
                }
            }
        },
        {
            name: "tipo_gobierno",
            label: "TIPO DE DEPENDENCIA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let dependencia = state.peticiones[dataIndex].tipo_gobierno;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{dependencia}</div>)
                }
            }
        },
        {
            name: "estatus_peticion",
            label: "ESTADO",
            options: {
                filter: true,
                sort: false,
                filterList: filtro && filtro2 && filtro3 !== '' ? [filtro, filtro2, filtro3] : filtro !== '' ? [filtro] : [],
                customFilterListOptions: { render: v => v ? `${v}` : '' },
                customBodyRenderLite: (dataIndex) => {
                    let estado = state.peticiones[dataIndex].estatus_peticion;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{estado}</div>)
                }
            }
        },
        {
            name: "respuesta",
            label: "RESPUESTA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let respuesta = state.peticiones[dataIndex].respuesta;
                    if (respuesta !== null) {
                        return (
                            <div style={{ height: '60px' }} className="d-flex align-items-center cursor-pointer" onClick={() => {
                                openModalDescripcion(state.peticiones[dataIndex].respuesta, "");
                            }} >
                                {respuesta.substring(0, 30)}
                                ...
                            </div>
                        )
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center justify-content-center">-</div>)
                    }
                }
            }
        },
        {
            name: "dias_restantes",
            label: "DIAS RESTANTES",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let dias = state.peticiones[dataIndex].dias_restantes;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{dias}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div>
                            <Button onClick={(event) => handleMenuClick(event, state.peticiones[dataIndex])}>
                                <IconUI size={'20px'} color={'black'}><AiOutlineSelect /></IconUI>
                            </Button>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                {(selectedItemId?.estatus_peticion_id === 4 || selectedItemId?.estatus_peticion_id === 6 || selectedItemId?.estatus_peticion_id === 9) && selectedItemId?.acuse === "SIN ACUSE" ?
                                    <MenuItem onClick={() => { handleAcuse(selectedItemId.id) }}>GENERAR ACUSE</MenuItem>
                                    :
                                    <div></div>
                                }
                                <MenuItem onClick={openModalImprimir}>IMPRIMIR</MenuItem>
                                <MenuItem onClick={openModalPeticion}>EDITAR</MenuItem>
                                <MenuItem onClick={openModalAdjuntos}>ADJUNTOS</MenuItem>
                                <MenuItem onClick={handleVerAcuse}>ACUSES</MenuItem>
                                <MenuItem onClick={openModalDetalles}>DETALLES</MenuItem>
                                <MenuItem onClick={openModalEliminar}>ELIMINAR</MenuItem>
                                <MenuItem onClick={openModalBitacora}>BITACORA</MenuItem>
                            </Menu>

                        </div>
                    )
                }
            }
        },

    ];

    //Peticiones
    const mostrarPeticiones = async () => {
        setLoader({ ...loader, loading: true })
        let peticiones;
        if (dashboard === 0) {

            peticiones = await services({ method: 'POST', service: `peticiones/listarPeticiones`, body: formulario });

            if (peticiones.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, peticiones: peticiones.data.data })
            } else {
                setLoader({ ...loader, loading: false })
            }
        } else {
            //qwerty1234
            peticiones = await services({ method: 'POST', service: `peticiones/peticionesPorEstatus`, body: { id_estatus: dashboard } });

            if (peticiones.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, peticiones: peticiones.data.data })
            } else {
                setLoader({ ...loader, loading: false })
            }
        }

    }

    const service = {
        1: 'documentos/oficioAyuntamiento',
        2: 'documentos/oficioDependenciaEstatal',
        3: 'documentos/oficioDependenciaFederal',
        4: 'documentos/oficioOrganismosAutonomos',
        5: 'documentos/acusePeticion',
        6: 'documentos/respuestaPeticion'
    }

    const descargarDocumentos = async (type) => {
        setLoader({ ...loader, loading: true })
        const documento = service[type]
        let response = await services({ method: 'POST', service: `${documento}`, body: { id: selectedItemId.id } });
        if (response.status === 200) {
            let peticiones;
            if (dashboard === 0 || formulario.bandeja === 0) {
                peticiones = await services({ method: 'POST', service: `peticiones/listarPeticiones`, body: formulario });
            } else {
                peticiones = await services({ method: 'POST', service: `peticiones/peticionesPorEstatus`, body: { id_estatus: dashboard } });
            }
            if (peticiones.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openDocumento: true, openImprimir: false, urlFrame: response.data.archivo, peticiones: peticiones.data.data })
            } else {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openImprimir: false })
                showNotification('error', 'Ups...Algo Sucedió', 'No se actualizo la tabla', null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openImprimir: false })
            showNotification('error', 'Ups...Algo Sucedió', response.data.message, null, 4);
        }
    }

    const editarPeticion = async () => {
        if (state.peticionSeleccionada.respuesta === '') {
            let newErrors = {};
            newErrors.respuesta = "Este Campo es requerido"
            setErrors(newErrors);
            showNotification('error', 'Ups...', 'Favor de rellenar los campos marcados', null, 4);
            setLoader({ ...loader, loading: false })
            return Object.keys(newErrors).length === 0;
        } else {
            setLoader({ ...loader, loading: true })
            let response = await services({
                method: 'POST', service: `peticiones/editarPeticion`, body: {
                    id: state.peticionSeleccionada.id,
                    peticion: state.peticionSeleccionada.peticion?.toUpperCase(),
                    tipo_peticion_id: state.peticionSeleccionada.tipo_peticion_id,
                    dependencia_id: state.peticionSeleccionada.dependencia_id,
                    estatus_peticion_id: state.peticionSeleccionada.estatus_peticion_id,
                    respuesta: state.peticionSeleccionada.respuesta?.toUpperCase(),
                    atencion_inmediata: state.peticionSeleccionada.atencion_inmediata,
                }
            });
            if (response.status === 200) {
                //qwerty 
                let peticiones;
                if (dashboard === 0 || formulario.bandeja === 0) {
                    peticiones = await services({ method: 'POST', service: `peticiones/listarPeticiones`, body: formulario });
                } else {
                    peticiones = await services({ method: 'POST', service: `peticiones/peticionesPorEstatus`, body: { id_estatus: dashboard } });
                }
                if (peticiones.status === 200) {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, peticiones: peticiones.data.data, openPeticion: false })
                    showNotification('success', 'Éxito', 'Su petición ha sido editada', null, 4);
                    setErrors({});

                } else {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, openPeticion: false })
                    showNotification('error', 'Ups...Algo Sucedió', peticiones.data.message, null, 4);
                    setErrors({});
                }
            } else {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openPeticion: false })
                showNotification('error', 'Ups...Algo Sucedió', response.data.message, null, 4);
                setErrors({});
            }
        }
    }

    const eliminarPeticion = async () => {
        setLoader({ ...loader, loading: true })
        let response = await services({
            method: 'POST', service: `peticiones/eliminarPeticion`, body: {
                id: state.peticionSeleccionada.id,
            }
        });
        if (response.status === 200) {

            let peticiones;
            if (dashboard === 0 || formulario.bandeja === 0) {
                peticiones = await services({ method: 'POST', service: `peticiones/listarPeticiones`, body: formulario });
            } else {
                peticiones = await services({ method: 'POST', service: `peticiones/peticionesPorEstatus`, body: { id_estatus: dashboard } });
            }
            if (peticiones.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, peticiones: peticiones.data.data, openEliminar: false })
                showNotification('success', 'Éxito', 'Su petición ha sido eliminada', null, 4);
            } else {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openEliminar: false })
                showNotification('error', 'Ups...Algo Sucedió', peticiones.data.message, null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openEliminar: false })
            showNotification('error', 'Ups...Algo Sucedió', response.data.message, null, 4);
        }
    }

    const enviarArchivos = async () => {
        setLoader({ ...loader, loading: true })
        if (selectedFile !== null) {
            const formData = new FormData();
            formData.append("id", selectedItemId.id);
            formData.append("archivo", selectedFile);
            let response = await services({
                method: 'POST', service: `documentos/adjuntos`, body: formData
            });
            if (response.status === 200) {
                let listaAdjuntos = await services({ method: 'POST', service: `documentos/listarAdjuntos`, body: { id: selectedItemId.id } });
                if (listaAdjuntos.status === 200) {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, adjuntos: listaAdjuntos.data.data })
                    showNotification('success', 'Éxito', 'Se ha cargado su archivo', null, 4);
                    setSelectedFile(null)
                    fileInputRef.current.value = '';
                } else {
                    setLoader({ ...loader, loading: false })
                    showNotification('error', 'Ups...', 'Ocurrió algo al cargar su archivo', null, 4);
                    setSelectedFile(null)
                    handleCloseAdjuntos()
                    fileInputRef.current.value = '';
                }
            } else {
                setLoader({ ...loader, loading: false })
                showNotification('error', 'Ups... Error', response.data.message.archivo[0], null, 4);
                setSelectedFile(null)
                handleCloseAdjuntos()
                fileInputRef.current.value = '';
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Favor de seleccionar un archivo', null, 4);
            setSelectedFile(null)
        }
    }

    const eliminarAdjunto = async (id_documento) => {

        setLoader({ ...loader, loading: true })
        const formData = new FormData();
        formData.append("id", id_documento);
        let response = await services({ method: 'POST', service: `documentos/eliminarAdjunto`, body: formData });
        if (response.status === 200) {
            let listaAdjuntos = await services({ method: 'POST', service: `documentos/listarAdjuntos`, body: { id: selectedItemId.id } });
            if (listaAdjuntos.status === 200) {
                setState({ ...state, adjuntos: listaAdjuntos.data.data })
                setLoader({ ...loader, loading: false })
                showNotification('success', 'Éxito', 'Se ha eliminado su archivo', null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Ocurrió algo al eliminar su archivo', null, 4);
            handleCloseAdjuntos()
        }
    }

    const descargarAdjunto = async (id_documento, name, tipo) => {
        setLoader({ ...loader, loading: true })
        const formData = new FormData();
        formData.append("id", selectedItemId.id);
        formData.append("id_documento", id_documento);
        formData.append("tipo_documento_id", tipo);
        let response = await services({
            method: 'POST', service: `documentos/descargarDocumento`, body: formData
        });
        if (response.status === 200) {
            setLoader({ ...loader, loading: false })
            showNotification('success', 'Éxito', 'Se ha descargado su archivo', null, 4);
            const link = document.createElement('a');
            link.href = `data:application/octet-stream;base64,${response.data.archivo}`;
            link.download = name;
            link.click();
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Ocurrió algo al descargar su archivo', null, 4);
        }
    }

    const exportar = async () => {
        setLoader({ ...loader, loading: true })

        let newForm = formulario;
        if (dashboard !== 0) {
            newForm.bandeja = 2
            newForm.estatus_filtro = dashboard
        }
        let response = await services({ method: 'POST', service: `peticiones/exportar`, body: formulario }, {
            responseType: 'arraybuffer',
        });
        if (response.status === 200) {
            setLoader({ ...loader, loading: false })
            showNotification('success', 'Éxito', 'Se ha descargado su archivo', null, 4);
            const link = document.createElement('a');
            link.href = `data:application/octet-stream;base64,${response.data.archivo}`;
            link.download = "Peticiones.xlsx";
            link.click();
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Ocurrió algo al descargar su archivo', null, 4);
        }
    }

    const buscador = async () => {
        setLoader({ ...loader, loading: true });
        let peticiones = await services({ method: 'POST', service: `peticiones/listarPeticiones`, body: { ...formulario, bandeja: 0 } });

        if (peticiones.status === 200) {
            setLoader({ ...loader, loading: false });
            setState({ ...state, peticiones: peticiones.data.data });
            setFormulario({ ...formulario, openFiltro: false, bandeja: 0 })
        } else {
            setLoader({ ...loader, loading: false })
        }
    }

    const limpiarBuscador = () => {
        setFormulario({
            ...formulario,
            openFiltro: true,
            municipios: [],
            localidades: [],
            cargos: [],
            titulos: [],
            ciudadano: null,
            apellido_paterno: null,
            apellido_materno: null,
            calle: null,
            colonia: null,
            cp: null,
            telefono: null,
            municipio_id: null,
            localidad_id: null,
            tipo_titulo_id: null,
            tipo_cargo_id: null,
            folio: null,
            atencion_inmediata: null,
            tipo_peticion_id: null,
            peticion: null,
            dependencia_id: null,
            tipo_gobierno: null,
            estatus_peticion_id: null,
            respuesta: null,
            observaciones: null,
            numero_gira: null,
            fecha_inicio: null,
            fecha_fin: null,
            edad_inicio: null,
            edad_fin: null
        });
    }

    // useEffect(() => {
    //     let isMounted = true
    //     if (isMounted) {
    //         mostrarPeticiones()
    //     }

    //     return () => {
    //         isMounted = false
    //     }
    //     // eslint-disable-next-line 
    // }, [dashboard])

    useEffect(() => {
        mostrarPeticiones()
        // eslint-disable-next-line 
    }, [dashboard])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>
                {loader.loading &&
                    <LoaderComponent />
                }

                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                        <Grid item >
                            <ColorButton startIcon={<AiOutlineSearch />} onClick={openModalFiltro}>
                                Filtro
                            </ColorButton>
                        </Grid>

                        <Grid item >
                            <ColorButton startIcon={<RiFileExcel2Line />} onClick={exportar}>
                                exportar
                            </ColorButton>
                        </Grid>
                    </Grid>
                </div>

                <DataTable
                    data={state.peticiones}
                    title={`TABLA DE PETICIONES`}
                    columns={columns}
                />
                {/* Modal para Filtro Especial */}
                <Modal
                    open={formulario.openFiltro}
                    onClose={handleCloseFiltro}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style4}>
                        <h5 className='TitulosProgramas'>
                            DATOS DEL PETICIONARIO
                        </h5>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    value={formulario.ciudadano}
                                    onChange={({ target }) => setFormulario({ ...formulario, ciudadano: target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="A.PATERNO"
                                    variant="outlined"
                                    value={formulario.apellido_paterno}
                                    onChange={({ target }) => setFormulario({ ...formulario, apellido_paterno: target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="A.MATERNO"
                                    variant="outlined"
                                    value={formulario.apellido_materno}
                                    onChange={({ target }) => setFormulario({ ...formulario, apellido_materno: target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="CALLE"
                                    variant="outlined"
                                    value={formulario.calle}
                                    onChange={({ target }) => setFormulario({ ...formulario, calle: target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="COLONIA"
                                    variant="outlined"
                                    value={formulario.colonia}
                                    onChange={({ target }) => setFormulario({ ...formulario, colonia: target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="CP"
                                    variant="outlined"
                                    value={formulario.cp}
                                    onChange={({ target }) => setFormulario({ ...formulario, cp: target.value })}
                                    onKeyPress={onlyNumber}
                                    inputProps={{ maxLength: 5 }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="TELÉFONO"
                                    variant="outlined"
                                    value={formulario.telefono}
                                    onChange={({ target }) => setFormulario({ ...formulario, telefono: target.value })}
                                    onKeyPress={onlyNumber}
                                    inputProps={{ maxLength: 10 }}

                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={formulario.municipios || []}
                                    getOptionLabel={(option) => option.municipio}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={formulario.municipios.find(option => option.id === formulario.municipio_id) || null}
                                    onChange={(event, newValue) => handleMunicipio(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="MUNICIPIO"
                                            error={!!errors.municipio}
                                            helperText={errors.municipio || ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="LOCALIDAD"
                                    variant="outlined"
                                    select
                                    onChange={handleLocalidad}
                                    value={formulario.localidad_id}
                                >
                                    {formulario.localidades?.length > 0 ?
                                        formulario.localidades.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.localidad}
                                            </MenuItem>
                                        ))
                                        :
                                        <MenuItem key={0} value={null}>
                                            SIN LOCALIDADES
                                        </MenuItem>
                                    }
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={formulario.cargos || []}
                                    getOptionLabel={(option) => option.tipo_cargo}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={formulario.cargos.find(option => option.id === formulario.tipo_cargo_id) || null}
                                    onChange={(event, newValue) => handleCargo(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="CARGO"
                                            error={!!errors.cargo}
                                            helperText={errors.cargo || ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="TITULO"
                                    variant="outlined"
                                    select
                                    onChange={handleTitulo}
                                    value={formulario.tipo_titulo_id}
                                >
                                    {formulario.titulos && formulario.titulos.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.tipo_titulo}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label=" DE EDAD"
                                    variant="outlined"
                                    value={formulario.edad_inicio}
                                    onChange={({ target }) => setFormulario({ ...formulario, edad_inicio: target.value })}
                                    onKeyPress={onlyNumber}
                                    inputProps={{ maxLength: 3 }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label=" A EDAD"
                                    id="textFile-nombre"
                                    variant="outlined"
                                    value={formulario.edad_fin}
                                    onChange={({ target }) => setFormulario({ ...formulario, edad_fin: target.value })}
                                    onKeyPress={onlyNumber}
                                    inputProps={{ maxLength: 3 }}
                                />
                            </Grid>
                        </Grid>

                        <h5 className='TitulosProgramas mt-3'>
                            DATOS DE LA PETICIÓN
                        </h5>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="FOLIO"
                                    variant="outlined"
                                    value={formulario.folio}
                                    onChange={({ target }) => setFormulario({ ...formulario, folio: target.value })}
                                    onKeyPress={onlyNumber}
                                    inputProps={{ maxLength: 5 }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="PRIORIDAD"
                                    variant="outlined"
                                    select
                                    error={!!errors.atencion}
                                    helperText={errors.atencion || ''}
                                    onChange={({ target }) => setFormulario({ ...formulario, atencion_inmediata: target.value })}
                                    value={formulario.atencion_inmediata}
                                >
                                    <MenuItem value={1}>
                                        INMEDIATA
                                    </MenuItem>
                                    <MenuItem value={0}>
                                        ALTA
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={state.tiposPeticion || []}
                                    getOptionLabel={(option) => option.tipo_peticion}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={state.tiposPeticion.find(option => option.id === formulario.tipo_peticion_id) || null}
                                    onChange={(event, newValue) => handleTipoPeticionesFiltro(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="TIPO DE PETICIÓN"
                                            error={!!errors.tipo}
                                            helperText={errors.tipo || ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="DESCRIPCIÓN"
                                    variant="outlined"
                                    value={formulario.peticion}
                                    onChange={({ target }) => setFormulario({ ...formulario, peticion: target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={state.dependencias || []}
                                    getOptionLabel={(option) => option.dependencia}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={state.dependencias.find(option => option.id === formulario.dependencia_id) || null}
                                    onChange={(event, newValue) => handleDependenciasFiltro(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="DEPENDENCIAS"
                                            error={!!errors.dependencia}
                                            helperText={errors.dependencia || ''}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="TIPO DE GOBIERNO"
                                    variant="outlined"
                                    select
                                    value={formulario.tipo_gobierno}
                                    onChange={handleTipoGobierno}

                                >
                                    <MenuItem value="FEDERAL">FEDERAL
                                    </MenuItem>
                                    <MenuItem value="ESTATAL">ESTATAL
                                    </MenuItem>
                                    <MenuItem value="MUNICIPAL">MUNICIPAL
                                    </MenuItem>
                                    <MenuItem value="AUTONOMO">AUTÓNOMO
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="ESTADO"
                                    variant="outlined"
                                    select
                                    onChange={({ target }) => setFormulario({ ...formulario, estatus_peticion_id: target.value })}
                                    value={formulario.estatus_peticion_id}
                                >
                                    <MenuItem value={1}>
                                        TURNADA
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        ATENDIDA POSITIVA
                                    </MenuItem>
                                    <MenuItem value={5}>
                                        NO CORRESPONDE
                                    </MenuItem>
                                    <MenuItem value={6}>
                                        NO PROCEDENTE
                                    </MenuItem>
                                    <MenuItem value={7}>
                                        ARCHIVO
                                    </MenuItem>
                                    <MenuItem value={8}>
                                        EN TRAMITE
                                    </MenuItem>
                                    <MenuItem value={9}>
                                        CANCELADO
                                    </MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="RESPUESTA"
                                    variant="outlined"
                                    value={formulario.respuesta}
                                    onChange={({ target }) => setFormulario({ ...formulario, respuesta: target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="OBSERVACIONES"
                                    variant="outlined"
                                    value={formulario.observaciones}
                                    onChange={({ target }) => setFormulario({ ...formulario, observaciones: target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="N° GIRA"
                                    variant="outlined"
                                    value={formulario.numero_gira}
                                    onChange={({ target }) => setFormulario({ ...formulario, numero_gira: target.value })}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="INICIO"
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{ width: 1 }}
                                        // slotProps={{ textField: { helperText: 'Please fill this field' } }}
                                        format="DD/MM/YYYY"
                                        views={['year', 'month', 'day']}
                                        value={formulario.fecha_inicio !== null ? dayjs(formulario.fecha_inicio) : null}
                                        onChange={handleInicio}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={4}>
                                <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                    <DatePicker
                                        label="FIN"
                                        slotProps={{ textField: { size: 'small' } }}
                                        sx={{ width: 1 }}
                                        // slotProps={{ textField: { helperText: 'Please fill this field' } }}
                                        format="DD/MM/YYYY"
                                        views={['year', 'month', 'day']}
                                        value={formulario.fecha_fin !== null ? dayjs(formulario.fecha_fin) : null}
                                        onChange={handleFin}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <Grid marginLeft="auto" marginBottom={2} marginTop={2} item >
                            <ColorButton className="me-2" onClick={() => {
                                limpiarBuscador()
                            }}
                            >
                                LIMPIAR
                            </ColorButton>
                            <ColorButton onClick={() => {
                                buscador()
                            }}
                            >
                                BUSCAR
                            </ColorButton>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para editar peticion */}
                <Modal
                    open={state.openPeticion}
                    onClose={() => handleClosePeticion()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas mb-4'>
                            EDITAR PETICIÓN
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={state.tiposPeticion || []}
                                    getOptionLabel={(option) => option.tipo_peticion}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={state.tiposPeticion.find(option => option.id === state.peticionSeleccionada.tipo_peticion_id) || null}
                                    onChange={(event, newValue) => handleTipoPeticiones(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="TIPO DE PETICIÓN"
                                            error={!!errors.tipo}
                                            helperText={errors.tipo || ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    size='small'
                                    label="PETICIÓN"
                                    error={!!errors.peticion}
                                    rows={4}
                                    helperText={errors.peticion || ''}
                                    value={state.peticionSeleccionada.peticion}
                                    onChange={({ target }) => setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, peticion: target.value } })}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    options={state.dependencias || []}
                                    getOptionLabel={(option) => option.dependencia}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={state.dependencias.find(option => option.id === state.peticionSeleccionada.dependencia_id) || null}
                                    onChange={(event, newValue) => handleDependencias(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="DEPENDENCIAS"
                                            error={!!errors.dependencia_id}
                                            helperText={errors.dependencia_id || ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="PRIORIDAD"
                                    variant="outlined"
                                    select
                                    error={!!errors.atencion}
                                    helperText={errors.atencion || ''}
                                    onChange={handlePrioridad}
                                    value={state.peticionSeleccionada.atencion_inmediata}
                                >
                                    <MenuItem value={1}>
                                        INMEDIATA
                                    </MenuItem>
                                    <MenuItem value={0}>
                                        ALTA
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="ESTADO"
                                    variant="outlined"
                                    select
                                    error={!!errors.atencion}
                                    helperText={errors.atencion || ''}
                                    onChange={handleStatusPeticion}
                                    value={state.peticionSeleccionada.estatus_peticion_id}
                                >
                                    <MenuItem value={1}>
                                        TURNADA
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        ATENDIDA POSITIVA
                                    </MenuItem>
                                    <MenuItem value={5}>
                                        NO CORRESPONDE
                                    </MenuItem>
                                    <MenuItem value={6}>
                                        NO PROCEDENTE
                                    </MenuItem>
                                    <MenuItem value={7}>
                                        ARCHIVO
                                    </MenuItem>
                                    <MenuItem value={8}>
                                        EN TRAMITE
                                    </MenuItem>
                                    <MenuItem value={9}>
                                        CANCELADO
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    size='small'
                                    label="RESPUESTA"
                                    error={!!errors.respuesta}
                                    rows={4}
                                    helperText={errors.respuesta || ''}
                                    value={state.peticionSeleccionada.respuesta}
                                    onChange={({ target }) => setState({ ...state, peticionSeleccionada: { ...state.peticionSeleccionada, respuesta: target.value } })}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarPeticion();
                                }}
                                >
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para ver detalles */}
                <Modal
                    open={state.openDetalles}
                    onClose={() => handleCloseDetalles()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas mb-4'>
                            DETALLES
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <span className=''></span>
                            <Grid item xs={12}>
                                <h5 className='mt-3 border-bottom' >DATOS SOLICITUD</h5>
                            </Grid>

                            <Grid item xs={12}>
                                <p>FOLIO: {state.detalles.folio_solicitud}</p>
                                <p>FECHA CREACIÓN: {state.detalles.fecha_creacion_solicitud}</p>
                                <p>FUENTE DE SOLICITUD: {state.detalles.fuente_solicitud}</p>
                                <p>OBSERVACIONES: {state.detalles.observaciones}</p>
                                <p>NÚMERO DE GIRA: {state.detalles.numero_gira}</p>
                                <p>LUGAR DE GIRA: {state.detalles.lugar_gira}</p>
                            </Grid>


                            <Grid item xs={12}>
                                <h5 className='mt-2 border-bottom' >DATOS PETICIÓN</h5>
                            </Grid>

                            <Grid item xs={12}>
                                <p>FOLIO: {state.detalles.folio}</p>
                                <p>ALTA: {state.detalles.fecha_creacion}</p>
                                <p>USUARIO DE REGISTRO: {state.detalles.usuario_registro}</p>
                                <p>CIUDADANO: {state.detalles.ciudadano}</p>
                                <p>CARGO: {state.detalles.cargo_ciudadano}</p>
                                <p>DIRECCIÓN: {state.detalles.Direccion}</p>
                                <p>TELÉFONO: {state.detalles.telefono}</p>
                                <p>DEPENDENCIA: {state.detalles.dependencia}</p>
                                <p>DESCRIPCIÓN: {state.detalles.tipo_peticion ? state.detalles.tipo_peticion + " " + state.detalles.peticion : state.detalles.peticion}</p>
                                <p>ESTADO: {state.detalles.estatus_peticion}</p>
                                <p>RESPUESTA: {state.detalles.respuesta}</p>
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    handleCloseDetalles();
                                }}
                                >
                                    Cerrar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para imprimir oficios */}
                <Modal
                    open={state.openImprimir}
                    onClose={() => handleCloseImprimir()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h3 className='TitulosProgramas mb-4'>
                            Oficios
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="Tipo de Oficio"
                                    variant="outlined"
                                    select
                                    onChange={handleDocumento}
                                    value={state.documento}
                                >
                                    <MenuItem value={1}>
                                        OFICIO PARA AYUNTAMIENTOS
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        OFICIO PARA DEPENDENCIA ESTATAL
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        OFICIO PARA DEPENDENCIA FEDERAL
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        OFICIO PARA ORGANISMO AUTÓNOMO
                                    </MenuItem>
                                    <MenuItem value={6}>
                                        Oficio Respuesta Petición
                                    </MenuItem>
                                </TextField>
                            </Grid>






                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    descargarDocumentos(state.documento);
                                }}
                                >
                                    GENERAR
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para ver documento */}
                <Modal
                    open={state.openDocumento}
                    onClose={() => handleCloseDocumento()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style3}>
                        <p className="text-center">DOCUMENTO</p>
                        <iframe style={{ width: "100%", height: "100%" }} title='DOCUMENTO' id="frameAceptacion" src={`data:application/pdf;base64,${state.urlFrame}`} className="preview-iframe" ></iframe>
                        <Button sx={{ mt: 2 }} onClick={() => handleCloseDocumento()} >Cerrar</Button>
                    </Box>
                </Modal>
                {/* Modal para ver Adjuntos */}
                <Modal
                    open={state.openAdjuntos}
                    onClose={() => handleCloseAdjuntos()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h3 className='TitulosProgramas mb-4'>
                            Archivos Adjuntos
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={2}
                        >
                            {state.adjuntos ?
                                state.adjuntos.map((item) => {
                                    return (
                                        <Grid item xs={12}>
                                            <Grid container columns={2} spacing={2} >
                                                <Grid item xs={1}>
                                                    <Link
                                                        component="button"
                                                        variant="body2"
                                                        onClick={() => {
                                                            descargarAdjunto(item.id, item.ruta, item.tipo_documento_id)
                                                        }}
                                                    >
                                                        {item.ruta}
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={1} align="end" >
                                                    {item.eliminar === 1 ?
                                                        <Button onClick={() => { eliminarAdjunto(item.id) }}>
                                                            <IconUI size={'20px'} color={'red'} ><AiOutlineDelete /></IconUI>
                                                        </Button>
                                                        :
                                                        <></>
                                                    }

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                :
                                <></>
                            }


                            <Grid item xs={12}  >
                                <input
                                    accept="*/*"
                                    style={{ display: 'none' }}
                                    id="file-upload-input"
                                    type="file"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                />
                                <label htmlFor="file-upload-input">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Subir Archivo
                                    </Button>
                                </label>
                                {selectedFile && (
                                    <div>
                                        <p>Nombre del archivo: {selectedFile.name}</p>
                                    </div>
                                )}
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    enviarArchivos();
                                }}
                                >
                                    Enviar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para ver descripcion/respuesta */}
                <Modal
                    open={state.openDescripcion}
                    onClose={() => handleCloseDescripcion()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box className='contenedorModalUrs' sx={style2} >
                        <Grid
                            container
                            columns={12}
                            spacing={2}
                        >
                            <Grid item xs={12} >
                                <p>{state.descripcion}</p>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para eliminar peticion */}
                <Modal
                    open={state.openEliminar}
                    onClose={() => handleCloseEliminar()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style2}>
                        <h3 className='TitulosProgramas mb-3'>
                            ¿ESTÁ SEGURO DE ELIMINAR LA PETICIÓN?
                        </h3>

                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <h4>FOLIO: {state.peticionSeleccionada.folio}</h4>
                                <h6>DESCRIPCIÓN:{state.peticionSeleccionada.peticion} </h6>
                            </Grid>
                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarPeticion();
                                }}

                                >
                                    Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>

                    </Box>
                </Modal>
                {/* Modal para eliminar peticion */}
                <Modal
                    open={state.openBitacora}
                    onClose={() => handleCloseBitacora()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style5}>
                        <h3 className='TitulosProgramas mb-3'>
                            Información Bitacora
                        </h3>

                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <div className='d-flex justify-content-center align-items-center w-100'>
                                <table className="table table-striped-columns my-3 w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col">Dependencia</th>
                                            <th scope="col">Estatus</th>
                                            <th scope="col">Peticion</th>
                                            <th scope="col">Respuesta</th>
                                            <th scope="col">Tipo de Peticion</th>
                                            <th scope="col">Fecha de Actualización</th>
                                            <th scope="col">Usuario</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {state.peticionHistorico.length !== 0 ?
                                            <>
                                                {state.peticionHistorico?.map((historico, i) => {
                                                    return (
                                                        <tr className={i % 2 === 0 ? 'bg-white' : 'bg-rosaClaro'} key={i}>
                                                            {/* <th scope="row">{i}</th> */}
                                                            <td>{historico.dependencia ? historico.dependencia : "Sin cambios"}</td>
                                                            <td>{historico.estatus_peticion ? historico.estatus_peticion : "Sin Cambios"}</td>
                                                            <td>{historico.peticion ? historico.peticion : "Sin cambios"}</td>
                                                            <td>{historico.respuesta ? historico.respuesta : "Sin cambios"}</td>
                                                            <td>{historico.tipo_peticion ? historico.tipo_peticion : "Sin cambios"}</td>
                                                            <td>{historico.updated_at ? dayjs(historico.updated_at).format('DD/MM/YYYY') : "Sin cambios"}</td>
                                                            <td>{historico.usuario ? historico.usuario : "Sin cambios"}</td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                            :
                                            <>
                                                {/* <div className='text-center my-4 border'>No se encuentran resultados</div> */}
                                            </>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {state.peticionHistorico.length === 0 ?
                                <>
                                    <div className='text-center my-4 text- w-100'>No se encuentran resultados</div>
                                </>
                                :
                                <>
                                </>
                            }
                        </Grid>

                    </Box>
                </Modal>
            </div>
        </>
    )
}





export default Peticiones;