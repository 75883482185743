import { Button } from '@mui/material'
import React, { useState, useContext, useEffect } from 'react';
import { services } from '../services/api';
import LoaderComponent from '../components/admin/LoaderComponent';
import { NotificacionContext } from '../context/notificacion_context';
import { Box, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { onlyNumber } from '../utils/inputsRules';




function Folios() {

    const style = {
        display: "flex",
        top: '50%',
        left: '50%',
        width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        /*border: '2px solid #661d40',*/
        boxShadow: 24,
        p: 4,
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 500,
        borderRadius: "40px",
    };
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const { showNotification } = useContext(NotificacionContext);

    const [loader, setLoader] = useState({
        loading: false
    })

    const [state, setState] = useState({
        folio_solicitudes: '',
        folio_peticiones: '',
        folio_audiencia: ''
    })
    
    const handleFolioSolicitud = (e) => {
        setState({ ...state, folio_solicitudes: e.target.value  })
    }
    const handleFolioPeticion = (e) => {
        setState({ ...state, folio_peticiones: e.target.value  })
    }

    const handleFolioAudiencia = (e) => {
        setState({ ...state, folio_audiencia: e.target.value  })
    }


    const reiniciar = async () => {
        setLoader({ ...loader, loading: true });
        const reinicio = await services({ method: 'POST', service: 'folios/reiniciarFoliosSolicitudesPeticiones', body:{ peticion: state.folio_peticiones, solicitud: state.folio_solicitudes } });
        if (reinicio.status === 200) {
            const folios = await services({ method: 'GET', service: `folios/mostrarFolios`, body: null });
            if (folios.status === 200) {
                setLoader({ ...loader, loading: false });
                setState({ ...state, folio_solicitudes: folios.data.data[0].solicitud, folio_peticiones: folios.data.data[0].peticion })
                showNotification('success', 'Éxito', 'Se han actualizado los folios', null, 4);

            } else {
                setLoader({ ...loader, loading: false });
                showNotification('error', 'Ups... Algo sucedió', 'Error actualizar folio', null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false });
            showNotification('error', 'Ups... Algo sucedió', 'Error actualizar folio', null, 4);
        }

    }

    const reiniciarAudiencia = async () => {
        setLoader({ ...loader, loading: true });
        const reinicio = await services({ method: 'POST', service: 'folios/reiniciarFoliosAudiencia', body: {folio: state.folio_audiencia} });
        if (reinicio.status === 200) {
            const folios = await services({ method: 'GET', service: `folios/mostrarFolios`, body: null });
            if (folios.status === 200) {
                setLoader({ ...loader, loading: false });
                setState({ ...state, folio_solicitudes: folios.data.data[0].solicitud, folio_peticiones: folios.data.data[0].peticion, folio_audiencia: folios.data.data[0].audiencia })
                showNotification('success', 'Éxito', 'Se ha actualizado el folio de audiencia', null, 4);

            } else {
                setLoader({ ...loader, loading: false });
                showNotification('error', 'Ups... Algo sucedió', 'Error actualizar folio', null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false });
            showNotification('error', 'Ups... Algo sucedió', 'Error actualizar folio', null, 4);
        }

    }

    const mostrarFolios = async () => {
        setLoader({ ...loader, loading: true });
        const folios = await services({ method: 'GET', service: `folios/mostrarFolios`, body: null });
        if (folios.status === 200) {
            setLoader({ ...loader, loading: false });
            setState({ ...state, folio_solicitudes: folios.data.data[0].solicitud, folio_peticiones: folios.data.data[0].peticion, folio_audiencia: folios.data.data[0].audiencia })
        } else {
            setLoader({ ...loader, loading: false });
            showNotification('error', 'Ups... Algo sucedió', 'Error al cargar contadores', null, 4);
        }
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarFolios()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])



    return (
        <>
            {loader.loading &&
                <LoaderComponent />
            }
            <div className='card--height--400  d-flex flex-row justify-content-center  py-5'>
                <div className="d-flex flex-column justify-content-center p-3 " >

                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas mb-4'>
                            Folios
                        </h3>

                        <Grid
                            container
                            columns={12}
                            spacing={6}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="Último Folio de Solicitud"
                                    variant="outlined"
                                    value={state.folio_solicitudes}
                                    onChange={handleFolioSolicitud}
                                    onKeyPress={onlyNumber}

                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="Último Folio de Petición "
                                    variant="outlined"
                                    value={state.folio_peticiones}
                                    onChange={handleFolioPeticion}
                                    onKeyPress={onlyNumber}


                                />
                            </Grid>


                            <Grid marginLeft="auto" marginRight="auto" item >
                                <ColorButton onClick={() => {
                                    reiniciar()
                                }}>
                                    Actualizar
                                </ColorButton>
                            </Grid>
                        </Grid>

                        <Grid
                            marginTop={3}
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item xs={6} >
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="Último Folio de Audiencia"
                                    variant="outlined"
                                    value={state.folio_audiencia}
                                    onChange={handleFolioAudiencia}
                                    onKeyPress={onlyNumber}
                                />
                            </Grid>

                        </Grid>

                        <Grid marginLeft="auto" marginTop={2} marginRight="auto" item xs={12}>
                            <ColorButton onClick={() => {
                                reiniciarAudiencia()
                            }}>
                                Actualizar
                            </ColorButton>
                        </Grid>
                    </Box>
                </div>
            </div>
        </>
    )
}

export default Folios