import React, { useContext } from 'react'
import MUIDataTable from "mui-datatables";
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { GlobalContext } from '../context/global_context';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              borderRadius: '30px !important',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
               backgroundColor: '#6A0F49 !important',
              //backgroundColor: '#bec3c9 !important',
              color: 'white'
            },
          },
        },
      },
    });

const DataTable = (dataInfo) => {

  
  const { data, columns, title } = dataInfo;
  const { setFiltro, setFiltro2, setFiltroUser, setAcuse, setFiltroLeida, searchText, setSearchText } = useContext(GlobalContext)
  const permiso = localStorage.getItem('EXPORTACIÓN');

  const customSearch = (searchQuery, currentRow, columns) => {
    const normalizedSearchQuery = searchQuery.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

    return currentRow.some((cell) => {
      const normalizedCell = String(cell).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
      return normalizedCell.includes(normalizedSearchQuery);
    });
  };
  
  const options = {
    selectableRows: 'none',
    tableBodyHeight: 'auto',
    searchText,
    customSearch,
    searchOpen: false,
    onTableChange: (action, tableState) => {
      if (action === 'search') {
        setSearchText(tableState.searchText);
      }
    },
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay coincidencias en la búsqueda",
        toolTip: "Clasificar",
        columnHeaderTooltip: (column) => `Clasificar por ${column.label}`
      },
      pagination: {
        next: "Siguiente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver columnas",
        filterTable: "Filtrar tabla",
      },
      filter: {
        all: "Todo",
        title: "Filtros",
        reset: "Restaurar",
      },
      viewColumns: {
        title: "Ver columnas",
        titleAria: "Mostrar/Ocultar columnas en la tabla",
      },
      selectedRows: {
        text: "columna(s) selecciona(as)",
        delete: "Eliminar",
        deleteAria: "Eliminar filas seleccionadas",
      },
    },
    // responsive: 'standard',
    downloadOptions: {
      filename: title + '.csv',
      utf8WithBom: false,
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },

    download: permiso ? true : false,
    // filter:false,
    print: false,
    viewColumns: false,
    onFilterChipClose: (e) => {
      setFiltro('')
      setFiltro2('')
      setFiltroUser('')
      setAcuse('')
      setFiltroLeida('')
    }
  };



  return (

    <Box sx={{ flexGrow: 1 }}>
      <Grid className='container mt-3 mb-3' maxWidth="xl">
        <ThemeProvider theme={getMuiTheme}>
          <MUIDataTable
            title={title}
            data={data}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </Grid>
    </Box>
  );
}

export default DataTable;