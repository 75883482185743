import { useContext } from "react";
import { useState } from "react";
import { services } from "../services/api";
import LoaderComponent from "../components/admin/LoaderComponent";
import { NotificacionContext } from "../context/notificacion_context";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { TextField, Modal, Box } from '@mui/material';
import { FaFilePen } from "react-icons/fa6";
import IconUI from "../components/iu/icon_ui";
import { FiArrowLeftCircle } from "react-icons/fi";
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from "react-icons/io";
import { FaDownload } from "react-icons/fa6";


const PeticionConsultaView = () => {
    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));
    const style3 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "90%",
        borderRadius: "40px",
    };
    const [state, setState] = useState({
        consulta: {},
        solicitud: true,
        openconsulta: false,
        openDocumento:false
    })
    const [formularioState, setFormulario] = useState({
        nombre: "",
        apellido_paterno: "",
        folio: ""
    })

    const [loader, setLoader] = useState({
        loading: false,
    });
    const { showNotification } = useContext(NotificacionContext)

    // const consultaPeticion = async () => {
    //     setLoader({ ...loader, loading: true })
    //     const formData = new FormData();
    //     // formData.append("nombre", "GUADALUPE");
    //     // formData.append("apellido_paterno", "SÁNCHEZ");
    //     // formData.append("folio", "08509");

    //     // formData.append("nombre", "MARIA DEL ROSARIO");
    //     // formData.append("apellido_paterno", "PEDRAZA");
    //     // formData.append("folio", "00009");

    // }

    const handleChangeConsultar = () => {
        setState({ ...state, solicitud: true })
    }

    const [errors, setErrors] = useState({});

    const handleNombre = (e) => {
        setFormulario({ ...formularioState, nombre: e.target.value })
    }
    const handleApellidoPaterno = (e) => {
        setFormulario({ ...formularioState, apellido_paterno: e.target.value })
    }
    const handlefolio = (e) => {
        setFormulario({ ...formularioState, folio: e.target.value })
    }

    const validarFormulario = () => {
        let newErrors = {};

        if (!formularioState.nombre) { newErrors.nombre = 'Este Campo es Requerido' }
        if (!formularioState.apellido_paterno) { newErrors.apellidoPaterno = 'Este Campo es Requerido' }
        if (formularioState.folio === "") { newErrors.folio = 'Este Campo es Requerido' }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }
    const handleCloseDocumento = () =>{
        setState({...state , openDocumento:false})
    }
    const handleConsultar = async () => {
        // setState({ ...state, })
        if (validarFormulario()) {
            setLoader({ ...loader, loading: true })
            const formData = new FormData();
            // formData.append("nombre", "RAFAEL");
            // formData.append("apellido_paterno", "RAMIREZ");
            // formData.append("folio", "07416");
            formData.append("nombre", formularioState.nombre);
            formData.append("apellido_paterno", formularioState.apellido_paterno);
            formData.append("folio", formularioState.folio);

            let response = await services({ method: 'POST', service: `enLinea/estatusPeticion`, body: formData });
            if (response.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, consulta: response.data.data, openconsulta: true, message: "", errorNombre: "", errorApellido_paterno: "", errorFolio: "" })
            } else {
                setLoader({ ...loader, loading: false })
                if (response.status !== 409) {
                    setState({
                        ...state,
                        message: "Información Inválida",
                        errorNombre: response.data.message.nombre,
                        errorApellido_paterno: response.data.message.apellido_paterno,
                        errorFolio: response.data.message.folio,
                    })
                    showNotification('error', 'Error', 'Información Inválida', null, 4);
                } else {
                    setState({ ...state, message: response.data.message, errorNombre: "", errorApellido_paterno: "", errorFolio: "" })
                }
            }
        }
        else {
            showNotification('error', 'Ups...Error ', 'Por favor llena todos los campos', null, 4);
            setLoader({ ...loader, loading: false })

        }
    }
    const handleCloseConsulta = () => {
        setState({ ...state, openconsulta: false })
        limpiarFormularioConsulta()
    }

    const limpiarFormularioConsulta = () => {
        setFormulario({
            ...formularioState,
            nombre: "",
            apellido_paterno: "",
            folio: ""
        })
    }
    const handleDowndloadFile = async () => {

        setLoader({ ...loader, loading: true })
        const formData = new FormData();
        formData.append("nombre", formularioState.nombre);
        formData.append("apellido_paterno", formularioState.apellido_paterno);
        formData.append("folio", formularioState.folio);

        let response = await services({ method: 'POST', service: `enLinea/descargarRespuestaPeticion`, body: formData });
        console.log(response.data, "data")
        if (response.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, documento: response.data, openDocumento: true })
        } else {
            setLoader({ ...loader, loading: false })
            if (response.status !== 409) {
                showNotification('error', 'Ups...', 'Ocurrio algo al cargar la información', null, 4);
            } else {
                showNotification('error', 'Ups...', response.data.message, null, 4);
                // setState({ ...state, message: response.data.message })
            }
        }
    }

    return (
        <div className="container">
            {loader.loading &&
                <LoaderComponent />
            }
            <div className="text-big pt-3">ATENCIÓN CIUDADANA MICHOACÁN</div>
            {state.openconsulta ?
                <div className="mb-2">
                    <div className="cta-circle" onClick={handleCloseConsulta}>
                        <IconUI size="30px">
                            <FiArrowLeftCircle />
                        </IconUI>
                    </div>
                    <div className="card card--rounded mt-3 p-3">
                        <div className="my-3 text-center card--GuindaSFA"  >CONSULTA DE PETICIONES EN LINEA</div>
                        <div >Apreciable <span className="text-bold">{state.consulta.ciudadano}</span></div>
                        <div>Le informamos que su petición referente a: {state.consulta.tipo_peticion} {state.consulta.peticion}</div>
                        <div className="py-3 card--RosaClaro mt-3">
                            <ul>
                                <li> Fue registrada con No. de Folio : {state.consulta.folio}</li>
                                <li> Con fecha: {state.consulta.fecha_creacion}</li>
                                <li> Canalizada a: {state.consulta.dependencia}</li>
                            </ul>
                        </div>
                        <div className="my-3 d-flex flex-md-row flex-column">
                            <div className="ms-3 p-3 card--RosaClaro">SITUACIÓN ACTUAL DE PETICIÓN: </div>
                            <div className="d-flex align-items-center estatusPeticion" style={{ color: '#576156' }}>
                                <div className="ms-3">
                                    <IconUI size="30px">
                                        {state.consulta.estatus_peticion === "POSITIVA" ?
                                            <IoIosAddCircleOutline/>
                                            :
                                            state.consulta.estatus_peticion === "NEGATIVA" ?
                                                <IoIosRemoveCircleOutline/>
                                                :
                                                <FaFilePen />
                                        }
                                    </IconUI>
                                </div>
                                <div className="ms-3 ">
                                    {state.consulta.estatus_peticion}
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="border">
                                <div className="m-3">Descripción de la respuesta: {state.consulta.respuesta ? state.consulta.respuesta : "S/R"}</div>

                                {state.consulta.documento_respuesta ?
                                    <div className="d-flex justify-content-end">
                                        <div className="document-download" onClick={handleDowndloadFile}>
                                            Documento de Respuesta <span><FaDownload /></span>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className="mt-3 card--RosaClaro">
                            <div className="m-3">DATOS DE LA DEPENDENCIA:</div>
                            <div className="m-3">Dirección:{state.consulta.direccion_dependencia ? state.consulta.direccion_dependencia : ''}</div>
                            <div className="m-3">Teléfono y responsable de atender:{state.consulta.telefono_dependencia}</div>
                        </div>
                        <div className="mt-5 text-center py-2 card--RosaClaro">Para mayor información. Atención Ciudadana: 800 7119 660 / 4433171484</div>
                    </div>
                </div>
                :

                <>
                    <div className="card card--GuindaSFA card--rounded mt-3">
                        <div className=" d-flex justify-content-center">
                            <div className={state.solicitud ? "cta-line-active ms-4 cta-line" : "ms-4 cta-line"} onClick={handleChangeConsultar}>CONSULTAR PETICIÓN</div>
                        </div>
                    </div>
                    {
                        state.solicitud ?
                            <div className="mt-5 card card--rounded">
                                <div>
                                    <div className=" my-3">Para consultar el estado de tu solicitud, por favor ingresa, la siguiente información. </div>
                                    <Grid
                                        container
                                        columns={12}
                                        spacing={3}
                                    >


                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                sx={{ borderRadius: "30px" }}
                                                fullWidth
                                                size='small'
                                                id="textFile-nombre"
                                                label="NOMBRE"
                                                variant="outlined"
                                                error={!!errors.nombre}
                                                helperText={errors.nombre || ''}
                                                value={formularioState.nombre}
                                                onChange={handleNombre}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                sx={{ borderRadius: "30px" }}
                                                fullWidth
                                                size='small'
                                                id="textFile-apellidoP"
                                                label="APELLIDO PATERNO"
                                                variant="outlined"
                                                error={!!errors.apellidoPaterno}
                                                helperText={errors.apellidoPaterno || ''}
                                                value={formularioState.apellido_paterno}
                                                onChange={handleApellidoPaterno}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                sx={{ borderRadius: "30px" }}
                                                fullWidth
                                                size='small'
                                                id="textFile-apellidoP"
                                                label="FOLIO"
                                                variant="outlined"
                                                error={!!errors.folio}
                                                helperText={errors.folio || ''}
                                                value={formularioState.folio}
                                                onChange={handlefolio}
                                            />
                                        </Grid>



                                        <Grid marginLeft="auto" item >
                                            <ColorButton onClick={() => {
                                                handleConsultar()
                                            }}>
                                                Consultar
                                            </ColorButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                            :
                            null
                    }
                </>
            }

            {state.message ?
                <>
                    <div className="card mt-3">
                        <div>
                            {state.message}
                        </div>
                        <div>
                            {state.errorNombre ? `Nombre: ${state.errorNombre[0]}` : null}
                        </div>
                        <div>
                            {state.errorApellido_paterno ? `Apellido Paterno: ${state.errorApellido_paterno[0]}` : null}
                        </div>
                        <div>
                            {state.errorFolio ? `Folio: ${state.errorFolio[0]}` : null}
                        </div>
                    </div>
                </>
                :
                null
            }
            <Modal
                open={state.openDocumento}
                onClose={() => handleCloseDocumento()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='contenedorModalUrs' sx={style3}>
                    <p className="text-center">Documento</p>
                    <iframe style={{ width: "100%", height: "100%" }} id="frameAceptacion" title="DOCUMENTO" src={`data:application/pdf;base64,${state.documento?.archivo}`} className="preview" ></iframe>
                    <Button sx={{ mt: 2 }} onClick={() => handleCloseDocumento()} >Cerrar</Button>
                </Box>
            </Modal>

        </div>
    )
}

export default PeticionConsultaView