import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../components/DataTableComponents';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import IconUI from '../components/iu/icon_ui';
import { FaFileSignature } from 'react-icons/fa6';
import { GrDocumentPdf } from "react-icons/gr";
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField, IconButton, InputAdornment } from '@mui/material';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const FirmaUser = () => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        //border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "auto",
        borderRadius: "40px",
    };
    const style3 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: 'background.paper',
        border: '2px solid #661d40',
        /*border: '2px solid #661d40',*/
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "90%",
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const revisor = localStorage.getItem('REVISAR_DOCUMENTOS')
    const firmante = localStorage.getItem('FIRMAR_DOCUMENTOS')

    const [state, setState] = useState({
        documentos: [],
        documentoSeleccionado: {},
        openFirma: false,
        openDocumento: false,
        password: ''
    });

    const { showNotification } = useContext(NotificacionContext)

    const [loader, setLoader] = useState({
        loading: true
    });

    const openModalFirma = (data) => {
        setState({ ...state, openFirma: true, documentoSeleccionado: data });
    }

    const openModalDocumento = async (data) => {
        setLoader({ ...loader, loading: true })
        const formData = new FormData();
        formData.append("id", data.peticion_id);
        formData.append("id_documento", data.id);
        formData.append("tipo_documento_id", data.tipo_documento_id);
        let response = await services({
            method: 'POST', service: `documentos/descargarDocumento`, body: formData
        });
        if (response.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openDocumento: true, urlFrame: response.data.archivo, documentoSeleccionado: data });
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', response.data.message, null, 4);
        }
    }

    const handleCloseFirma = () => {
        setState({
            ...state, openFirma: false
        });
    }

    const handleCloseDocumento = () => {
        setState({ ...state, openDocumento: false });
    }

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword })
    }



    const columns = [
        {
            name: "folio",
            label: "FOLIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let folio = state.documentos[dataIndex].folio;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{folio}</div>)
                }
            }
        },
        {
            name: "ruta",
            label: "DOCUMENTO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let documento = state.documentos[dataIndex].ruta;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{documento}</div>)
                }
            }
        },
        {
            name: "dependencia",
            label: "DEPENDENCIA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let dependencia = state.documentos[dataIndex].dependencia;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{dependencia}</div>)
                }
            }
        },
        {
            name: "firmado",
            label: "ESTADO",
            options: {
                filter: true,
                sort: false,
                // filterList: estado !== '' ? [estado] : null,
                // customFilterListOptions: { render: v => v ? `${v}` : '' },
                customBodyRenderLite: (dataIndex) => {
                    let firmado = state.documentos[dataIndex].firmado;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{firmado}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center">
                            {firmante ? 
                            <Tooltip title="FIRMAR DOCUMENTO">
                                {state.documentos[dataIndex].firmado === "VALIDADO SIN FIRMA" ?
                                    <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                        onClick={
                                            () => {
                                                openModalFirma(state.documentos[dataIndex])
                                            }}
                                    >
                                        <IconUI size={'20px'} color={'black'}><FaFileSignature /></IconUI>

                                    </div>
                                    :

                                    <div style={{ height: 30, width: 30, zIndex: 0, }} className='justify-content-center mt-2'>
                                        <IconUI size={'20px'} color={'gray'}><FaFileSignature /></IconUI>
                                    </div>

                                }                                
                            </Tooltip>
                            :
                            <></>
                            }
                            <Tooltip title="VER DOCUMENTO">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openModalDocumento(state.documentos[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><GrDocumentPdf /></IconUI>

                                </div>
                            </Tooltip>
                        </div>
                    </>
                )
            }
        },
    ];

    const [selectedCer, setSelectedCer] = useState(null);
    const [selectedKey, setSelectedKey] = useState(null);

    const handleCerChange = (event) => {
        const file = event.target.files[0];
        setSelectedCer(file);
    };

    const handleKeyChange = (event) => {
        const file = event.target.files[0];
        setSelectedKey(file);
    };

    const handleValidar = async () => {

        setLoader({ ...loader, loading: true })
        let response = await services({
            method: 'POST', service: `firma/validarParaFirmar`, body: {
                id_peticion: state.documentoSeleccionado.peticion_id,
                tipo_documento: state.documentoSeleccionado.tipo_documento_id
            }
        });
        if (response.status === 200) {
            let documentos = await services({ method: 'GET', service: `firma/listarPorFirmar`, body: null });
            if (documentos.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openDocumento: false, documentos: documentos.data.data });
                showNotification('success', 'Éxito', 'Se ha validado su archivo', null, 4);
            }else{
                setLoader({ ...loader, loading: false })
                setState({ ...state, openDocumento: false });
                showNotification('error', 'Ups...', 'Se ha validado su archivo pero hubo un error al actualizar la tabla', null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openDocumento: false });
            showNotification('error', 'Ups...', 'Ha ocurrido un error al validar', null, 4);
        }
    }

    const enviarFirmar = async () => {
        setLoader({ ...loader, loading: true })
        if (selectedCer !== null && selectedKey !== null && state.password !== '') {
            const formData = new FormData();
            formData.append("id_peticion", state.documentoSeleccionado.peticion_id);
            formData.append("tipo_documento", state.documentoSeleccionado.tipo_documento_id);
            formData.append("cer", selectedCer);
            formData.append("key", selectedKey);
            formData.append("clave_fiel", state.password);
            let response = await services({ method: 'POST', service: `firma/firmar`, body: formData });
            if (response.status === 200) {
                let documentos = await services({ method: 'GET', service: `firma/listarPorFirmar`, body: null });
                if (documentos.status === 200) {
                    setState({ ...state, documentos: documentos.data.data, openFirma: false, password: '' })
                    setLoader({ ...loader, loading: false })
                    showNotification('success', 'Éxito', 'Se ha firmado su archivo', null, 4);
                    setSelectedCer(null);
                    setSelectedKey(null);
                    // fileInputRef.current.value = '';
                }
            } else {
                setLoader({ ...loader, loading: false })
                showNotification('error', 'Ups...', response.data.message, null, 4);
                setState({ ...state, openFirma: false, password: '' })
                setSelectedCer(null);
                setSelectedKey(null);
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Favor de llenar todos los campos', null, 4);
        }
    }

    const mostrarDocumentos = async () => {

        setLoader({ ...loader, loading: true })

        let documentos = await services({ method: 'GET', service: `firma/listarPorFirmar`, body: null });

        if (documentos.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, documentos: documentos.data.data })
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...Error ', 'No fue posible obtener la información', null, 4);
        }
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarDocumentos()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>

                {loader.loading &&
                    <LoaderComponent />
                }

                <DataTable
                    data={state.documentos}
                    title={`TABLA DE FIRMAS`}
                    columns={columns}
                />


                {/*Modal para firma */}
                <Modal
                    open={state.openFirma}
                    onClose={handleCloseFirma}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas mb-3'>
                            Firmar Documento
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <input
                                    type="file"
                                    id="fileInputCer"
                                    accept=".cer"
                                    style={{ display: 'none' }}
                                    onChange={handleCerChange}
                                />

                                <TextField
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="Archivo .Cer"
                                    variant="outlined"
                                    onClick={() => {
                                        document.getElementById('fileInputCer').click();
                                    }}
                                    value={selectedCer ? selectedCer.name : ''}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <input
                                    type="file"
                                    id="fileInputKey"
                                    style={{ display: 'none' }}
                                    accept=".key"
                                    onChange={handleKeyChange}
                                />

                                <TextField
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="Archivo .key"
                                    variant="outlined"
                                    onClick={() => {
                                        document.getElementById('fileInputKey').click();
                                    }}
                                    value={selectedKey ? selectedKey.name : ''}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    size='small'
                                    label="Contraseña"
                                    variant="outlined"
                                    type={state.showPassword ? 'text' : 'password'}
                                    // error={!!errors.password}
                                    // helperText={errors.password || ''}
                                    value={state.password}
                                    onChange={({ target }) => setState({ ...state, password: target.value })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                >
                                                    {state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        sx: { borderRadius: 30 }
                                    }}
                                />
                            </Grid>


                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    enviarFirmar();

                                }}>
                                    Firmar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/* Modal para ver documento */}
                <Modal
                    open={state.openDocumento}
                    onClose={() => handleCloseDocumento()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style3}>
                        <p className="text-center">DOCUMENTO</p>
                        <iframe style={{ width: "100%", height: "100%" }} title='DOCUMENTO' id="frameAceptacion" src={`data:application/pdf;base64,${state.urlFrame}`} className="preview-iframe" ></iframe>
                        <div className='d-flex justify-content-center'>
                            <Button sx={{ mt: 2 }} onClick={() => handleCloseDocumento()} >CERRAR</Button>
                            {
                                state.documentoSeleccionado.firmado === "SIN FIRMA" && revisor ?
                                    <Button sx={{ mt: 2 }} onClick={() => handleValidar()} >VALIDAR</Button>
                                    :
                                    <Button sx={{ mt: 2 }} disabled>VALIDAR</Button>
                            }
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    )
}

export default FirmaUser