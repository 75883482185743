import { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {

    const [globalState, setGlobalState] = useState({});
    const [filtro, setFiltro] = useState('')
    const [filtroLeida, setFiltroLeida] = useState('')
    const [filtro2, setFiltro2] = useState('')
    const [filtroUser, setFiltroUser] = useState('')
    const [peticion, setPeticion] = useState('')
    const [acuse, setAcuse] = useState('')
    const [searchText, setSearchText] = useState('');
    const [dashboard, setDashboard] = useState(0)
    const [filtro3, setFiltro3] = useState('')

    return (
        <GlobalContext.Provider
            value={{
                globalState,
                setGlobalState,
                filtroLeida, 
                setFiltroLeida,
                filtro,
                setFiltro,
                filtro2,
                setFiltro2,
                filtroUser,
                setFiltroUser,
                acuse, 
                setAcuse,
                peticion, 
                setPeticion,
                searchText,
                setSearchText,
                setDashboard,
                dashboard, 
                setFiltro3,
                filtro3             
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};