import React, { useState, useEffect, useContext, useRef } from 'react';
import DataTable from '../components/DataTableComponents';
import IconUI from '../components/iu/icon_ui';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField, MenuItem, Link, Menu } from '@mui/material';
import { AiOutlineSelect, AiOutlineDelete } from 'react-icons/ai';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';
import { BsCircleFill, BsCheck2Circle } from "react-icons/bs";
import moment from 'moment/moment';
import { GlobalContext } from '../context/global_context';



const PeticionesDep = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "85%",
        borderRadius: "40px",
    };
    const style2 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: 'auto',
        borderRadius: "40px",
    };
    const style3 = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1200,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "90%",
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const { showNotification } = useContext(NotificacionContext)

    // state del componente
    const [state, setState] = useState({
        peticiones: [],
        peticionSeleccionada: {},
        dependencias: [],
        tiposPeticion: [],
        detalles: [],
        adjuntos: [],
        openRechazada: false,
        openTramite: false,
        openRespuesta: false,
        openDetalles: false,
        openImprimir: false,
        openAdjuntos: false,
        openDocumento: false,
        openDescripcion: false,
        documento: null,
        urlFrame: '',
        respuesta: '',
        tipoResp: '',
    });

    //palabra de inicio
    const { filtro, filtro2, acuse, filtroLeida, dashboard, filtro3 } = useContext(GlobalContext)


    const fileInputRef = useRef(null);

    //modificar peticion

    const [errors, setErrors] = useState({});



    const openModalRespuesta = () => {
        setState({ ...state, openRespuesta: true, peticionSeleccionada: selectedItemId });
        handleMenuClose()
    }

    const openModalRechazada = () => {
        setState({ ...state, openRechazada: true, peticionSeleccionada: selectedItemId });
        handleMenuClose()
    }


    const openModalTramite = () => {
        setState({ ...state, openTramite: true, peticionSeleccionada: selectedItemId });
        handleMenuClose()
    }

    const openModalDetalles = async () => {
        setLoader({ ...loader, loading: true })
        let detalles = await services({ method: 'POST', service: `peticiones/mostrarPeticion`, body: { id: selectedItemId.id } });
        if (detalles.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, detalles: detalles.data.data, openDetalles: true });
            handleMenuClose()
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'ups... Algo sucedió', 'Error al obtener información', null, 4);
            handleMenuClose()
        }

    }

    const openModalDescripcion = (tipo, data) => {
        setState({ ...state, openDescripcion: true, descripcion: tipo + " " + data });
    }

    const openModalAdjuntos = async () => {
        setLoader({ ...loader, loading: true })
        let listaAdjuntos = await services({ method: 'POST', service: `documentos/listarAdjuntos`, body: { id: selectedItemId.id } });
        if (listaAdjuntos.status === 200) {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openAdjuntos: true, adjuntos: listaAdjuntos.data.data });
            handleMenuClose()
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...Algo Sucedió', 'No se lograron cargar los archivos adjuntos', null, 4);
        }
        const dataResponse2 = new FormData();
            dataResponse2.append("id",selectedItemId.id);
            let response2 = await services({
              method: 'POST', service: `peticiones/peticionesEnTramite`, body: dataResponse2
            });
            if(response2.status === 200){
              // showNotification('success', 'Éxito', 'Se modificó correctamente la petición', null, 4);
            }
    }

    const handleTipo = (e) => {
        setState({ ...state, tipoResp: e.target.value })
    }

    const handleDocumento = (e) => {
        setState({ ...state, documento: e.target.value })
    }

    //state del loading
    const [loader, setLoader] = useState({
        loading: true
    });

    const handleCloseRespuesta = () => {
        setState({ ...state, openRespuesta: false });
        setErrors({})
    }

    const handleCloseRechazada = () => {
        setState({ ...state, openRechazada: false });
        setErrors({});
    }

    const handleCloseTramite = () => {
        setState({ ...state, openTramite: false });
        setErrors({});
    }

    const handleCloseDetalles = () => {
        setState({ ...state, openDetalles: false });
    }

    const handleCloseDescripcion = () => {
        setState({ ...state, openDescripcion: false });
    }

    const handleCloseImprimir = () => {
        setState({ ...state, openImprimir: false });
    }

    const handleCloseDocumento = () => {
        setState({ ...state, openDocumento: false });
    }

    const handleCloseAdjuntos = () => {
        setState({ ...state, openAdjuntos: false });
        setSelectedFile(null)
    }

    // manejo de archivos
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = async(event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    // manejo de archivos

    //Botón de acciones

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const handleMenuClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setSelectedItemId(data);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    //Botón de acciones


    //columnas y lógica de la tabla
    const columns = [
        {
            name: "leida",
            label: "LEÍDA",
            options: {
                filter: true,
                sort: true,
                display: false,
                filterList: filtroLeida !== '' ? [filtroLeida] : [],
                customFilterListOptions: { render: v => v ? `${v}` : '' },
                customBodyRenderLite: (dataIndex) => {
                    let leida = state.peticiones[dataIndex].leida;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>
                        {leida}
                    </div>)
                }
            }
        },
        {
            name: "semaforo",
            label: " ",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let semaforo = state.peticiones[dataIndex].semaforo;
                    if (semaforo === 'ATENDIDA') {
                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'green'}><BsCheck2Circle /></IconUI>
                        </div>)
                    } else if (semaforo === 'VERDE') {

                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'green'}><BsCircleFill /></IconUI>
                        </div>)
                    } else if (semaforo === 'AMARILLO') {
                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'yellow'}><BsCircleFill /></IconUI>
                        </div>)
                    } else {
                        return (<div className='d-flex align-items-center' style={{ height: '30px' }}>
                            <IconUI size={'20px'} color={'red'}><BsCircleFill /></IconUI>
                        </div>)
                    }


                }
            }
        },
        {
            name: "acuse",
            label: "ACUSE",
            options: {
                filter: true,
                sort: true,
                display: false,
                filterList: acuse !== '' ? [acuse] : [],
                customFilterListOptions: { render: v => v ? `${v}` : '' },
                customBodyRenderLite: (dataIndex) => {
                    let acuse = state.peticiones[dataIndex].acuse;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>
                        {acuse}
                    </div>)
                }
            }
        },
        {
            name: "folio",
            label: "FOLIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let folio = state.peticiones[dataIndex].folio;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>
                        {folio}
                    </div>)
                }
            }
        },
        {
            name: "fecha_creacion",
            label: "FECHA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let alta = moment(state.peticiones[dataIndex].fecha_creacion, 'DD-MM-YYYY');
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}> {alta.format('DD-MM-YYYY')} </div>)
                }
            }
        },
        {
            name: "ciudadano",
            label: "NOMBRE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.peticiones[dataIndex].ciudadano;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "municipio",
            label: "MUNICIPIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let municipio = state.peticiones[dataIndex].municipio;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{municipio}</div>)
                }
            }
        },
        {
            name: "peticion",
            label: "PETICIÓN",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let descripcion = state.peticiones[dataIndex].peticion;
                    let tipo = state.peticiones[dataIndex].tipo_peticion;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center cursor-pointer " onClick={() => {
                        openModalDescripcion(state.peticiones[dataIndex].tipo_peticion, state.peticiones[dataIndex].peticion);

                    }}>

                        {tipo? tipo + " " + descripcion.substring(0, 30): descripcion.substring(0, 30)}
                        ...
                    </div>)
                }
            }
        },
        {
            name: "dependencia",
            label: "DEPENDENCIA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let dependencia = state.peticiones[dataIndex].dependencia;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{dependencia}</div>)
                }
            }
        },
        {
            name: "estatus_peticion",
            label: "ESTADO",
            options: {
                filter: true,
                sort: false,
                filterList: filtro && filtro2 && filtro3 !== '' ? [filtro, filtro2, filtro3] : filtro !== '' ? [filtro] : [],
                customFilterListOptions: { render: v => v ? `${v}` : '' },
                customBodyRenderLite: (dataIndex) => {
                    let estado = state.peticiones[dataIndex].estatus_peticion;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{estado}</div>)
                }
            }
        },
        {
            name: "respuesta",
            label: "RESPUESTA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let respuesta = state.peticiones[dataIndex].respuesta;
                    if (respuesta !== null) {
                        return (
                            <div style={{ height: '60px' }} className="d-flex align-items-center cursor-pointer" onClick={() => {
                                openModalDescripcion(state.peticiones[dataIndex].respuesta, "");
                            }} >
                                {respuesta.substring(0, 30)}
                                ...
                            </div>
                        )
                    } else {
                        return (<div style={{ height: '60px' }} className="d-flex align-items-center justify-content-center">-</div>)
                    }
                }
            }
        },
        {
            name: "dias_restantes",
            label: "DIAS RESTANTES",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let dias = state.peticiones[dataIndex].dias_restantes;
                    return (<div style={{ height: '60px' }} className="d-flex align-items-center">{dias}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    return (
                        <div>
                            <Button onClick={(event) => handleMenuClick(event, state.peticiones[dataIndex])}>
                                <IconUI size={'20px'} color={'black'}><AiOutlineSelect /></IconUI>
                            </Button>
                            {
                                selectedItemId?.estatus_peticion_id === 1 || selectedItemId?.estatus_peticion_id === 2 ?
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                        <MenuItem onClick={openModalTramite}>EN TRÁMITE</MenuItem>
                                        <MenuItem onClick={openModalRechazada}>NO CORRESPONDE</MenuItem>
                                        <MenuItem onClick={openModalAdjuntos}>ADJUNTOS</MenuItem>
                                    </Menu>
                                    :
                                    selectedItemId?.estatus_peticion_id === 9 || selectedItemId?.estatus_peticion_id === 5 || (selectedItemId?.estatus_peticion_id === 4 && acuse !== "SIN ACUSE") || (selectedItemId?.estatus_peticion_id === 6 && acuse === "CON ACUSE") ?
                                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                            <MenuItem onClick={openModalAdjuntos}>ADJUNTOS</MenuItem>
                                            <MenuItem onClick={openModalDetalles}>DETALLES</MenuItem>
                                        </Menu>
                                        :
                                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                            <MenuItem onClick={openModalAdjuntos}>ADJUNTOS</MenuItem>
                                            <MenuItem onClick={openModalDetalles}>DETALLES</MenuItem>
                                            <MenuItem onClick={openModalRespuesta}>RESPUESTA</MenuItem>
                                        </Menu>
                            }
                        </div>
                    )
                }
            }
        },

    ];

    const [formulario] = useState({
        openFiltro: false,
        municipios: [],
        localidades: [],
        cargos: [],
        titulos: [],

        ciudadano: null,
        apellido_paterno: null,
        apellido_materno: null,
        calle: null,
        colonia: null,
        cp: null,
        telefono: null,
        municipio_id: null,
        localidad_id: null,
        tipo_titulo_id: null,
        tipo_cargo_id: null,
        folio: null,
        atencion_inmediata: null,
        tipo_peticion_id: null,
        peticion: null,
        dependencia_id: null,
        tipo_gobierno: null,
        estatus_peticion_id: null,
        respuesta: null,
        observaciones: null,
        numero_gira: null,
        fecha_inicio: null,
        fecha_fin: null,
        edad_inicio: null,
        edad_fin: null,
        bandeja: 1
    });



    //Peticiones
    const mostrarPeticiones = async () => {
        setLoader({ ...loader, loading: true })
        let peticiones;
        if (dashboard === 0) {

            peticiones = await services({ method: 'POST', service: `peticiones/listarPeticiones`, body: formulario });

            if (peticiones.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, peticiones: peticiones.data.data })
            } else {
                setLoader({ ...loader, loading: false })
            }
        } else{
            console.log(filtro)
            console.log(filtro2)
            console.log(filtroLeida)
           
            peticiones = await services({ method: 'POST', service: `peticiones/peticionesPorEstatus`, body: { id_estatus: dashboard } });

            if (peticiones.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, peticiones: peticiones.data.data })
            } else {
                setLoader({ ...loader, loading: false })
            }
        }

    }

    const service = {
        1: 'documentos/oficioAyuntamiento',
        2: 'documentos/oficioDependenciaEstatal',
        3: 'documentos/oficioDependenciaFederal',
        4: 'documentos/oficioOrganismosAutonomos',
        5: 'documentos/acusePeticion'
    }

    const descargarDocumentos = async (type) => {
        setLoader({ ...loader, loading: true })
        const documento = service[type]
        let response = await services({ method: 'POST', service: `${documento}`, body: { id: selectedItemId.id } });
        if (response.status === 200) {
            let peticiones = await services({ method: 'POST', service: `peticiones/listarPeticiones`, body: formulario });
            if (peticiones.status === 200) {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openDocumento: true, openImprimir: false, urlFrame: response.data.archivo, peticiones: peticiones.data.data })
            } else {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openImprimir: false })
                showNotification('error', 'Ups...Algo Sucedió', 'No se actualizo la tabla', null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openImprimir: false })
            showNotification('error', 'Ups...Algo Sucedió', response.data.message, null, 4);
        }
    }

    const respuestaPeticion = async () => {
        setLoader({ ...loader, loading: true })

        if (state.tipoResp !== '' && state.respuesta !== '') {
            let response = await services({
                method: 'POST', service: `peticiones/darRespuestaPeticion`, body: {
                    id: state.peticionSeleccionada.id,
                    estatus_peticion: state.tipoResp,
                    respuesta: state.respuesta
                }
            });
            if (response.status === 200) {
                let peticiones = await services({ method: 'POST', service: `peticiones/listarPeticiones`, body: formulario });
                if (peticiones.status === 200) {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, peticiones: peticiones.data.data, openRespuesta: false })
                    showNotification('success', 'Éxito', 'Su respuesta ha sido enviada', null, 4);
                } else {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, openRespuesta: false })
                    showNotification('error', 'Ups...Algo Sucedió', peticiones.data.message, null, 4);
                }
            } else {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openRespuesta: false })
                showNotification('error', 'Ups...Algo Sucedió', response.data.message, null, 4);
            }
        } else {
            let newErrors = {};
            if (!state.tipoResp) { newErrors.tipo = "Este Campo es requerido" }
            if (!state.respuesta) { newErrors.respuesta = "Este Campo es requerido" }
            setLoader({ ...loader, loading: false })
            setErrors(newErrors);
            showNotification('error', 'Ups...', 'Favor de rellenar los campos marcados', null, 4);
            return Object.keys(newErrors).length === 0;
        }
    }

    const aceptarPeticion = async (value) => {
        setLoader({ ...loader, loading: true })
        if ((value === 5 || value === 8 || value === 9) && state.respuesta !== '') {
            let response = await services({
                method: 'POST', service: `peticiones/aceptarPeticion`, body: {
                    id: selectedItemId.id,
                    estatus_peticion: value,
                    respuesta: state.respuesta
                }
            });
            if (response.status === 200) {
                let peticiones = await services({ method: 'POST', service: `peticiones/listarPeticiones`, body: formulario });
                if (peticiones.status === 200) {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, peticiones: peticiones.data.data, openRechazada: false, openTramite: false, respuesta: '' })
                    showNotification('success', 'Éxito', response.data.message, null, 4);
                } else {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, openRechazada: false, respuesta: '' })
                    showNotification('error', 'Ups...Algo Sucedió', peticiones.data.message, null, 4);
                }
            } else {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openRechazada: false, openTramite: false, respuesta: '' })
                showNotification('error', 'Ups...Algo Sucedió', response.data.message, null, 4);
            }
        } else {
            let newErrors = {};
            newErrors.motivo = "Este Campo es requerido"
            setLoader({ ...loader, loading: false })
            setErrors(newErrors);
            showNotification('error', 'Ups...', 'Favor de rellenar el mensaje', null, 4);
            return Object.keys(newErrors).length === 0;

        }
    }

    const enviarArchivos = async () => {
        setLoader({ ...loader, loading: true })
        if (selectedFile !== null) {
            const formData = new FormData();
            
            formData.append("id", selectedItemId.id);
            formData.append("archivo", selectedFile);
            let response = await services({
                method: 'POST', service: `documentos/adjuntos`, body: formData
            });
            if (response.status === 200) {
                let listaAdjuntos = await services({ method: 'POST', service: `documentos/listarAdjuntos`, body: { id: selectedItemId.id } });
                if (listaAdjuntos.status === 200) {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, adjuntos: listaAdjuntos.data.data })
                    showNotification('success', 'Éxito', 'Se ha cargado su archivo', null, 4);
                    setSelectedFile(null)
                    fileInputRef.current.value = '';
                } else {
                    setLoader({ ...loader, loading: false })
                    showNotification('error', 'Ups...', 'Ocurrió algo al cargar su archivo', null, 4);
                    setSelectedFile(null)
                    handleCloseAdjuntos()
                    fileInputRef.current.value = '';
                }
            } else {
                setLoader({ ...loader, loading: false })
                showNotification('error', 'Ups... Error', response.data.message.archivo[0], null, 4);
                setSelectedFile(null)
                handleCloseAdjuntos()
                fileInputRef.current.value = '';
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Favor de seleccionar un archivo', null, 4);
            setSelectedFile(null)
        }
    }

    const eliminarAdjunto = async (id_documento) => {

        setLoader({ ...loader, loading: true })
        const formData = new FormData();
        formData.append("id", id_documento);
        let response = await services({ method: 'POST', service: `documentos/eliminarAdjunto`, body: formData });
        if (response.status === 200) {
            let listaAdjuntos = await services({ method: 'POST', service: `documentos/listarAdjuntos`, body: { id: selectedItemId.id } });
            if (listaAdjuntos.status === 200) {
                setState({ ...state, adjuntos: listaAdjuntos.data.data })
                setLoader({ ...loader, loading: false })
                showNotification('success', 'Éxito', 'Se ha eliminado su archivo', null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Ocurrió algo al eliminar su archivo', null, 4);
            handleCloseAdjuntos()
        }
    }

    const descargarAdjunto = async (id_documento, name, tipo) => {
        setLoader({ ...loader, loading: true })
        const formData = new FormData();
        formData.append("id", selectedItemId.id);
        formData.append("id_documento", id_documento);
        formData.append("tipo_documento_id", tipo);

        let response = await services({
            method: 'POST', service: `documentos/descargarDocumento`, body: formData
        });
        if (response.status === 200) {
            setLoader({ ...loader, loading: false })
            showNotification('success', 'Éxito', 'Se ha descargado su archivo', null, 4);
            const link = document.createElement('a');
            link.href = `data:application/octet-stream;base64,${response.data.archivo}`;
            link.download = name;
            link.click();
        } else {
            setLoader({ ...loader, loading: false })
            showNotification('error', 'Ups...', 'Ocurrió algo al descargar su archivo', null, 4);
        }
    }

     // useEffect(() => {
    //     let isMounted = true
    //     if (isMounted) {
    //         mostrarPeticiones()
    //     }

    //     return () => {
    //         isMounted = false
    //     }
    //     // eslint-disable-next-line 
    // }, [dashboard])

    useEffect(() => {
        mostrarPeticiones()
        // eslint-disable-next-line 
    }, [dashboard])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>
                {loader.loading &&
                    <LoaderComponent />
                }

                <DataTable
                    data={state.peticiones}
                    title={`TABLA DE PETICIONES`}
                    columns={columns}
                />
                {/* Modal para respuesta peticion */}
                <Modal
                    open={state.openRespuesta}
                    onClose={() => handleCloseRespuesta()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h3 className='TitulosProgramas mb-4'>
                            RESPUESTA
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="TIPO"
                                    variant="outlined"
                                    select
                                    error={!!errors.tipo}
                                    helperText={errors.tipo || ''}
                                    onChange={handleTipo}
                                    value={state.tipoResp}
                                >
                                    <MenuItem value={4}>
                                        ATENDIDA POSITIVA
                                    </MenuItem>
                                    <MenuItem value={6}>
                                        NO PROCEDENTE
                                    </MenuItem>
                                    <MenuItem value={9}>
                                        CANCELADO
                                    </MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    multiline
                                    size='small'
                                    label="MENSAJE"
                                    error={!!errors.respuesta}
                                    rows={4}
                                    helperText={errors.respuesta || ''}
                                    value={state.respuesta}
                                    onChange={({ target }) => setState({ ...state, respuesta: target.value.toUpperCase() })}
                                />
                            </Grid>


                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    respuestaPeticion();
                                }}
                                >
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/* Modal para ver detalles */}
                <Modal
                    open={state.openDetalles}
                    onClose={() => handleCloseDetalles()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas mb-4'>
                            Detalles
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <span className=''></span>
                            <Grid item xs={12}>
                                <h5 className='mt-3 border-bottom' >Datos Solicitud</h5>
                            </Grid>

                            <Grid item xs={12}>
                                <p>FOLIO: {state.detalles.folio_solicitud}</p>
                                <p>FECHA CREACIÓN: {state.detalles.fecha_creacion_solicitud}</p>
                                <p>FUENTE DE SOLICITUD: {state.detalles.fuente_solicitud}</p>
                                <p>OBSERVACIONES: {state.detalles.observaciones}</p>
                                <p>NUMERO DE GIRA: {state.detalles.numero_gira}</p>
                                <p>LUGAR DE GIRA: {state.detalles.lugar_gira}</p>
                            </Grid>


                            <Grid item xs={12}>
                                <h5 className='mt-2 border-bottom' >Datos Petición</h5>
                            </Grid>

                            <Grid item xs={12}>
                                <p>FOLIO: {state.detalles.folio}</p>
                                <p>ALTA: {state.detalles.fecha_creacion}</p>
                                <p>USUARIO DE REGISTRO: {state.detalles.usuario_registro}</p>
                                <p>CIUDADANO: {state.detalles.ciudadano}</p>
                                <p>CARGO: {state.detalles.cargo_ciudadano}</p>
                                <p>DIRECCIÓN: {state.detalles.Direccion}</p>
                                <p>TELÉFONO: {state.detalles.telefono}</p>
                                <p>DEPENDENCIA: {state.detalles.dependencia}</p>
                                <p>DESCRIPCIÓN: {state.detalles.tipo_peticion? state.detalles.tipo_peticion +" "+ state.detalles.peticion : state.detalles.peticion}</p>
                                <p>ESTADO: {state.detalles.estatus_peticion}</p>
                                <p>RESPUESTA: {state.detalles.respuesta}</p>
                            </Grid>



                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    handleCloseDetalles();
                                }}
                                >
                                    Cerrar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/* Modal para imprimir oficios */}
                <Modal
                    open={state.openImprimir}
                    onClose={() => handleCloseImprimir()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h3 className='TitulosProgramas mb-4'>
                            Oficios
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="Tipo de Oficio"
                                    variant="outlined"
                                    select
                                    onChange={handleDocumento}
                                    value={state.documento}
                                >
                                    <MenuItem value={1}>
                                        Oficio para Ayuntamientos
                                    </MenuItem>
                                    <MenuItem value={2}>
                                        Oficio para Dependencia Estatal
                                    </MenuItem>
                                    <MenuItem value={3}>
                                        Oficio para Dependencia Federal
                                    </MenuItem>
                                    <MenuItem value={4}>
                                        Oficio para Organismo Autónomos
                                    </MenuItem>
                                </TextField>
                            </Grid>






                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    descargarDocumentos(state.documento);
                                }}
                                >
                                    Enviar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/* Modal para ver documento */}
                <Modal
                    open={state.openDocumento}
                    onClose={() => handleCloseDocumento()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style3}>
                        <p className="text-center">Documento</p>
                        <iframe style={{ width: "100%", height: "100%" }} id="frameAceptacion" title='DOCUMENTO' src={`data:application/pdf;base64,${state.urlFrame}`} className="preview-iframe" ></iframe>
                        <Button sx={{ mt: 2 }} onClick={() => handleCloseDocumento()} >Cerrar</Button>
                    </Box>
                </Modal>
                {/* Modal para ver Adjuntos */}
                <Modal
                    open={state.openAdjuntos}
                    onClose={() => handleCloseAdjuntos()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h3 className='TitulosProgramas mb-4'>
                            ARCHIVOS ADJUNTOS
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={2}
                        >
                            {state.adjuntos ?
                                state.adjuntos.map((item) => {
                                    return (
                                        <Grid item xs={12}>
                                            <Grid container columns={2} spacing={2} >
                                                <Grid item xs={1}>
                                                    <Link
                                                        component="button"
                                                        variant="body2"
                                                        onClick={() => {
                                                            descargarAdjunto(item.id, item.ruta, item.tipo_documento_id) 
                                                        }}
                                                    >
                                                        {item.ruta}
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={1} align="start" >
                                                    {item.eliminar === 1 ?
                                                        <Button onClick={() => { eliminarAdjunto(item.id) }}>
                                                            <IconUI size={'20px'} color={'red'} ><AiOutlineDelete /></IconUI>
                                                        </Button>
                                                        :
                                                        <></>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                                :
                                <></>
                            }


                            <Grid item xs={12}  >
                                <input
                                    accept="*/*"
                                    style={{ display: 'none' }}
                                    id="file-upload-input"
                                    type="file"
                                    onChange={handleFileChange}
                                    ref={fileInputRef}
                                />
                                <label htmlFor="file-upload-input">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                        startIcon={<CloudUploadIcon />}
                                    >
                                        Subir Archivo
                                    </Button>
                                </label>
                                {selectedFile && (
                                    <div>
                                        <p>Nombre del archivo: {selectedFile.name}</p>
                                    </div>
                                )}
                            </Grid>






                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    enviarArchivos();
                                }}
                                >
                                    Enviar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/* Modal para Rechazar peticion */}
                <Modal
                    open={state.openRechazada}
                    onClose={() => handleCloseRechazada()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h4 className='TitulosProgramas mb-4'>
                            RECHAZAR PETICIÓN
                        </h4>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    size='small'
                                    label="MENSAJE"
                                    error={!!errors.motivo}
                                    rows={4}
                                    helperText={errors.motivo || ''}
                                    value={state.respuesta}
                                    onChange={({ target }) => setState({ ...state, respuesta: target.value.toUpperCase() })}
                                />
                            </Grid>


                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    aceptarPeticion(5);
                                }}
                                >
                                    Enviar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para En Tramite peticion */}
                <Modal
                    open={state.openTramite}
                    onClose={() => handleCloseTramite()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style2}>
                        <h4 className='TitulosProgramas mb-4'>
                            EN TRÁMITE
                        </h4>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    size='small'
                                    label="MENSAJE"
                                    error={!!errors.motivo}
                                    rows={4}
                                    helperText={errors.motivo || ''}
                                    value={state.respuesta}
                                    onChange={({ target }) => setState({ ...state, respuesta: target.value.toUpperCase() })}
                                />
                            </Grid>


                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    aceptarPeticion(8);
                                }}
                                >
                                    Enviar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* Modal para ver descripción/respuesta */}
                <Modal
                    open={state.openDescripcion}
                    onClose={() => handleCloseDescripcion()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >

                    <Box className='contenedorModalUrs' sx={style2} >
                        <Grid
                            container
                            columns={12}
                            spacing={2}
                        >
                            <Grid item xs={12} >
                                <p>{state.descripcion}</p>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

            </div>
        </>
    )
}





export default PeticionesDep;