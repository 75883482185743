import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../components/DataTableComponents';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Tooltip } from '@mui/material';
import IconUI from '../components/iu/icon_ui';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField } from '@mui/material';
import { onlyLetter } from '../utils/inputsRules';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';

const TiposDePeticion = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "auto",
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const [state, setState] = useState({
        peticiones: [],
        peticionSeleccionado: {},
        openEditar: false,
        openEliminar: false,
    });

    const [formularioState, setFormularioState] = useState({
        nombre: '',
        nombreError: ''
    });

    const [loader, setLoader] = useState({
        loading: true
    });

    const [openModal, setopenModal] = useState(false)

    const openmodaleditar = (data) => {
        setState({ ...state, openEditar: true, peticionSeleccionado: data });
    }
    const openmodaleliminar = (data) => {
        setState({ ...state, openEliminar: true, peticionSeleccionado: data });
    }
    const handleCloseEditar = () => {
        setState({
            ...state, openEditar: false
        });
    }
    const handleCloseEliminar = () => {
        setState({
            ...state, openEliminar: false
        });
    }

    const { showNotification } = useContext(NotificacionContext)

    const columns = [
        {
            name: "tipo_peticion",
            label: "NOMBRE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.peticiones[dataIndex].tipo_peticion;
                    return (<div className='d-flex align-items-center' style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center">
                            <Tooltip title="EDITAR PETICIÓN">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodaleditar(state.peticiones[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                </div>
                            </Tooltip>

                            <Tooltip title="ELIMINAR PETICIÓN">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodaleliminar(state.peticiones[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>

                                </div>
                            </Tooltip>

                        </div>
                    </>
                )
            }
        },



    ];

    const handleNombre = (e) => {
        setFormularioState({ ...formularioState, nombre: e.target.value })
    }

    const handlePeticionSeleccionadoNombre = (e) => {
        const objeto = {
            id: state.peticionSeleccionado.id,
            tipo_peticion: e.target.value,
        }
        setState({ ...state, peticionSeleccionado: objeto });
    }

    const validarFormulario = () => {
        const nombreError = formularioState.nombre === '';
        const atencionError = formularioState.atencion === '';
        return !nombreError && !atencionError
    }

    const crearPeticion = async () => {

        if (validarFormulario()) {
            setLoader(true)
            const Peticion = await services({
                method: 'POST', service: 'catTipoPeticiones/crear', body: {
                    tipo_peticion: formularioState.nombre,
                }
            })
            if (Peticion) {
                let peticiones = await services({ method: 'GET', service: `catTipoPeticiones/listarPeticiones`, body: null })
                if (peticiones) {
                    setState({ ...state, peticiones: peticiones.data.data, open: false })
                    setFormularioState({ ...formularioState, nombre: '', atencion: '' })
                    showNotification('success', 'Tipo de Peticiones', 'Se creó correctamente el tipo de petición.', null, 4);
                    setopenModal(false);
                } else {
                    showNotification('error', 'ups... Algo sucedió', 'Error al agregar petición', null, 4);
                    setFormularioState({ ...formularioState, nombre: '', atencion: '' })
                    setopenModal(false);
                }
            } else {
                showNotification('error', 'ups... Algo sucedió', 'Error al agregar petición', null, 4);
            }
        } else {

            showNotification('error', 'ups...Error ', 'Por favor llena todos los campos', null, 4);
        }

    }

    const editarPeticion = async () => {
        setLoader(true)
        const EditPeticion = await services({
            method: 'POST', service: `catTipoPeticiones/editarPeticion`, body: {
                id: state.peticionSeleccionado.id,
                tipo_peticion: state.peticionSeleccionado.tipo_peticion,
            }
        })
        if (EditPeticion) {
            setLoader(true)
            let peticiones = await services({ method: 'GET', service: `catTipoPeticiones/listarPeticiones`, body: null })
            setState({ ...state, peticiones: peticiones.data.data, openEditar: false })
            showNotification('success', 'Tipo de Peticiones', 'Se editó correctamente el tipo de petición.', null, 4);
            setLoader(false)
        } else {
            setLoader(false)
            showNotification('error', 'ups...Error ', 'Ocurrio un error al editar', null, 4);
        }
    }

    const eliminarPeticion = async () => {
        setLoader(true)
        const EliminarPeticion = await services({ method: 'POST', service: `catTipoPeticiones/eliminarPeticion`, body: { id: state.peticionSeleccionado.id } })
        if (EliminarPeticion) {
            setLoader(true)
            let peticiones = await services({ method: 'GET', service: `catTipoPeticiones/listarPeticiones`, body: null })
            if (peticiones) {
                setState({ ...state, peticiones: peticiones.data.data, openEliminar: false })
                showNotification('success', 'Tipo de Peticiones', 'Se eliminó correctamente el tipo de petición.', null, 4);
                setLoader(false)
            } else {
                setLoader(false)
                showNotification('error', 'ups...Error ', 'Ocurrió un error al eliminar', null, 4);
            }

        } else {
            showNotification('error', 'ups...Error ', 'Ocurrió un error al eliminar', null, 4);
            setLoader(false)
        }
    }

    const mostrarTipoDePeticiones = async () => {

        let peticiones = await services({ method: 'GET', service: `catTipoPeticiones/listarPeticiones`, body: null });

        if (peticiones) {
            setState({ ...state, peticiones: peticiones.data.data })
        }
        setLoader(false)
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarTipoDePeticiones()
        }

        return () => {
            isMounted = false
        }
         // eslint-disable-next-line
    }, [])
    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>

                {loader.loading &&
                    <LoaderComponent />
                }
                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                        <Grid item >
                            <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() => { setopenModal(true) }}>
                                Añadir Nueva Petición
                            </ColorButton>
                        </Grid>
                    </Grid>
                </div>
                <DataTable
                    data={state.peticiones}
                    title={`TABLA TIPO DE PETICIONES`}
                    columns={columns}
                />

                {/*Modal para Guardar */}
                <Modal
                    open={openModal}
                    onClose={() => setopenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>

                        <h3 className='TitulosProgramas mb-3'>
                            NUEVA PETICIÓN
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    error={formularioState.nombreError}
                                    value={formularioState.nombre}
                                    onChange={handleNombre}
                                    onKeyPress={(event) => {
                                        onlyLetter(event, 55);
                                    }}
                                />
                            </Grid>                        

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    crearPeticion();
                                }}>
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Editar */}
                <Modal
                    open={state.openEditar}
                    onClose={handleCloseEditar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas mb-3'>
                            EDITAR PETICIÓN
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="NOMBRE"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={state.peticionSeleccionado.tipo_peticion}
                                    onChange={handlePeticionSeleccionadoNombre}
                                    onKeyPress={(event) => {
                                        onlyLetter(event, 55);
                                    }}
                                />
                            </Grid>
                            

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarPeticion();

                                }}>
                                    Editar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Eliminar */}
                <Modal
                    open={state.openEliminar}
                    onClose={handleCloseEliminar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style}>
                        <h3 className='TitulosProgramas  mb-5'>
                            ¿ESTÁ SEGURO DE ELIMINAR LA PETICIÓN?
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >

                            <Grid item xs={12}>
                                <h4>
                                    {state.peticionSeleccionado.tipo_peticion}
                                </h4>
                            </Grid>

                            <Grid margin="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarPeticion();
                                }}>
                                    Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>


            </div>
        </>
    )
}

export default TiposDePeticion