import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../components/DataTableComponents';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Tooltip, MenuItem } from '@mui/material';
import IconUI from '../components/iu/icon_ui';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField } from '@mui/material';
import { onlyLetter } from '../utils/inputsRules';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';


const Localidades = () => {
   
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "auto",
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const [state, setState] = useState({
        localidades: [],
        municipios: [],
        localidadSeleccionada: {},
        openEditar: false,
        openEliminar: false,
    });

    const [formularioState, setFormularioState] = useState({
        localidad: '',
        municipio_id: '',
        localidadError: '',
        municipio_idError: '',
    });

    const [loader, setLoader] = useState({
        loading: true
    });

    const [openModal, setopenModal] = useState(false)

    const openmodaleditar = (data) => {
        setState({ ...state, openEditar: true, localidadSeleccionada: data });
    }
    const openmodaleliminar = (data) => {
        setState({ ...state, openEliminar: true, localidadSeleccionada: data });
    }

    const handleCloseEditar = () => {
        setState({
            ...state, openEditar: false
        });
    }

    const handleCloseEliminar = () => {
        setState({
            ...state, openEliminar: false
        });
    }

    const { showNotification } = useContext(NotificacionContext)

    const columns = [
        {
            name: "localidad",
            label: "LOCALIDAD",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let localidad = state.localidades[dataIndex].localidad;
                    return (<div className=' d-flex align-items-center' style={{ height: '60px', color: "#6A0F49" }}>{localidad}</div>)
                }
            }
        },
        {
            name: "municipio",
            label: "MUNICIPIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let localidad = state.localidades[dataIndex].municipio;
                    return (<div className=' d-flex align-items-center' style={{ height: '60px', color: "#6A0F49" }}>{localidad}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center">
                            <Tooltip title="EDITAR LOCALIDAD">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodaleditar(state.localidades[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                </div>
                            </Tooltip>

                            <Tooltip title="ELIMINAR LOCALIDAD">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openmodaleliminar(state.localidades[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>

                                </div>
                            </Tooltip>

                        </div>
                    </>
                )
            }
        },



    ];

    const handleLocalidad = (e) => {
        setFormularioState({ ...formularioState, localidad: e.target.value.toUpperCase() })
    }

    const handleMunicipio = (e) => {
        setFormularioState({ ...formularioState, municipio_id: e.target.value })
    }

    const handleLocalidadSeleccionada = (e) => {
        setState({ ...state, localidadSeleccionada: { ...state.localidadSeleccionada, localidad: e.target.value } })
    }

    const handleMunicipioSeleccionado = (e) => {
        setState({ ...state, localidadSeleccionada: { ...state.localidadSeleccionada, municipio_id: e.target.value } })
    }

    const validarFormulario = () => {
        const localidadError = formularioState.localidad === '';
        const municipio_idError = formularioState.municipio_id === '';
        return !localidadError && !municipio_idError
    }

    const crearLocalidad = async () => {

        if (validarFormulario()) {
            setLoader({ ...loader, loading: true })
            const localidad = await services({
                method: 'POST', service: 'catLocalidades/crear', body: {
                    localidad: formularioState.localidad,
                    municipio_id: formularioState.municipio_id
                }
            })

            if (localidad.status === 200) {
                let localidades = await services({ method: 'GET', service: `catLocalidades/listar`, body: null })
                if (localidades.status === 200) {
                    setLoader({ ...loader, loading: false })
                    setFormularioState({ ...formularioState, localidad: '' })
                    setState({ ...state, localidades: localidades.data.data })
                    showNotification('success', 'Localidad', 'Agregada con Éxito', null, 4);
                    setopenModal(false);
                } else {
                    showNotification('error', 'Ups... Algo sucedió', localidades.data.message, null, 4);
                    setFormularioState({ ...formularioState, localidad: '', municipio_id:'' })
                    setopenModal(false);
                    setLoader({ ...loader, loading: false });
                }
            } else {
                showNotification('error', 'Ups... Algo sucedió', localidad.data.message, null, 4);
                setFormularioState({ ...formularioState, localidad: '', municipio_id:'' })
                setopenModal(false);
                setLoader({ ...loader, loading: false });

            }

        } else {
            showNotification('error', 'ups...Error ', 'Por favor llena todos los campos', null, 4);
        }

    }

    const editarLocalidad = async () => {
        if (state.localidadSeleccionada.localidad === "") {
            showNotification('error', 'Ups... ', 'Favor de llenar los campos', null, 4);
        } else {
            setLoader({ ...loader, loading: true })
            const editarLocalidad = await services({
                method: 'POST', service: `catLocalidades/editar`, body: {
                    id: state.localidadSeleccionada.id,
                    localidad: state.localidadSeleccionada.localidad?.toUpperCase(),
                    municipio_id: state.localidadSeleccionada.municipio_id,
                }
            })
            if (editarLocalidad.status === 200) {
                let localidades = await services({ method: 'GET', service: `catLocalidades/listar`, body: null })

                if (localidades.status === 200) {
                    setState({ ...state, localidades: localidades.data.data, openEditar: false })
                    showNotification('success', 'Localidad', 'Editada con Éxito', null, 4);
                    setLoader({ ...loader, loading: false })

                } else {
                    setLoader({ ...loader, loading: false })
                    setState({ ...state, openEditar: false })
                    showNotification('error', 'Ups... ', localidades.data.message, null, 4);
                }
            } else {
                setLoader({ loading: false })
                setState({ ...state, openEditar: false })
                showNotification('error', 'Ups... ', editarLocalidad.data.message, null, 4);
            }
        }
    }

    const eliminarLocalidad = async () => {
        setLoader({ ...loader, loading: true })
        const eliminarLocalidad = await services({ method: 'POST', service: `catLocalidades/eliminar`, body: { id: state.localidadSeleccionada.id } })
        if (eliminarLocalidad.status === 200) {
            setLoader({ ...loader, loading: true })
            let localidades = await services({ method: 'GET', service: `catLocalidades/listar`, body: null })
            if (localidades.status === 200) {
                setState({ ...state, localidades: localidades.data.data, openEliminar: false })
                showNotification('success', 'Localidad Eliminada con Éxito', '', null, 4);
                setLoader({ ...loader, loading: false })
            } else {
                setLoader({ ...loader, loading: false })
                setState({ ...state, openEliminar: false })
                showNotification('error', 'Ups...', localidades.data.message, null, 4);
            }
        } else {
            setLoader({ ...loader, loading: false })
            setState({ ...state, openEliminar: false })
            showNotification('error', 'Ups...', eliminarLocalidad.data.message, null, 4);
        }
    }

    const mostrarLocalidades = async () => {
        setLoader({ ...loader, loading: true })
        let localidades = await services({ method: 'GET', service: `catLocalidades/listar`, body: null })
        let municipios = await services({ method: 'GET', service: `catMunicipios/listar`, body: null })

        if (localidades.status === 200 && municipios.status === 200) {
            setState({ ...state, localidades: localidades.data.data, municipios: municipios.data.data })
            setLoader({ ...loader, loading: false })

        } else {
            showNotification('error', 'Ups...', 'No se logro cargar toda la información', null, 4);
            setLoader({ ...loader, loading: false })
        }
    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarLocalidades()
        }
        return () => {
            isMounted = false
        }
         // eslint-disable-next-line
    }, [])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>

                {loader.loading &&
                    <LoaderComponent />
                }
                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                        <Grid item >
                            <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() => { setopenModal(true) }}>
                                Añadir Nueva Localidad
                            </ColorButton>
                        </Grid>
                    </Grid>
                </div>
                <DataTable
                    data={state.localidades}
                    title={`TABLA DE LOCALIDADES`}
                    columns={columns}
                />

                {/*Modal para Guardar */}
                <Modal
                    open={openModal}
                    onClose={() => setopenModal(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>

                        <h3 className='TitulosProgramas mb-3'>
                            NUEVA LOCALIDAD
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >
                            <Grid item xs={12}>
                                <TextField

                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="MUNICIPIO"
                                    variant="outlined"
                                    select
                                    error={!!formularioState.municipio_idError}
                                    helperText={formularioState.municipio_idError || ''}
                                    onChange={handleMunicipio}
                                    value={formularioState.municipio_id}
                                >
                                    {state.municipios && state.municipios.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.municipio}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    error={formularioState.localidadError}
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="LOCALIDAD"
                                    variant="outlined"
                                    value={formularioState.localidad}
                                    onChange={handleLocalidad}
                                    onKeyPress={(event) => {
                                        onlyLetter(event, 55);
                                    }}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    crearLocalidad();
                                }}>
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Editar */}
                <Modal
                    open={state.openEditar}
                    onClose={handleCloseEditar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas mb-3'>
                            EDITAR LOCALIDAD
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Titulo"
                                    label="MUNICIPIO"
                                    variant="outlined"
                                    select
                                    // error={!!formularioState.municipio_idError}
                                    // helperText={formularioState.municipio_idError || ''}
                                    onChange={handleMunicipioSeleccionado}
                                    value={state.localidadSeleccionada.municipio_id}
                                >
                                    {state.municipios && state.municipios.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.municipio}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    id="textFile-nombre"
                                    label="LOCALIDAD"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    value={state.localidadSeleccionada.localidad}
                                    onChange={handleLocalidadSeleccionada}
                                    onKeyPress={(event) => {
                                        onlyLetter(event, 55);
                                    }}
                                />
                            </Grid>

                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarLocalidad();

                                }}>
                                    Editar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Eliminar */}
                <Modal
                    open={state.openEliminar}
                    onClose={handleCloseEliminar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style}>
                        <h3 className='TitulosProgramas  mb-5'>
                            ¿ESTÁ SEGURO DE ELIMINAR LA LOCALIDAD?
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={4}
                        >

                            <Grid item xs={12}>
                                <h4>
                                    {state.localidadSeleccionada.localidad}
                                </h4>
                            </Grid>

                            <Grid margin="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarLocalidad();

                                }}>
                                    Eliminar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

            </div>

        </>

    )
}





export default Localidades;