import React, { useState, useEffect, useContext } from 'react';
import DataTable from '../components/DataTableComponents';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoaderComponent from '../components/admin/LoaderComponent';
import { Box, Modal, TextField, MenuItem, Autocomplete } from '@mui/material';
import { services } from '../services/api';
import { NotificacionContext } from '../context/notificacion_context';
import { onlyNumber } from '../utils/inputsRules';
import Tooltip from '@mui/material/Tooltip';
import IconUI from '../components/iu/icon_ui';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import "dayjs/locale/es"
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { FaHandsHelping } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { IoMdArrowRoundBack } from "react-icons/io";
import { GlobalContext } from '../context/global_context';



const SolicitudCiudadano = () => {

    const goTo = useNavigate();
    const { setPeticion } = useContext(GlobalContext)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        // border: '2px solid #661d40',
        border: '2px solid #661d40',
        boxShadow: 24,
        p: 4,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        overflowY: "scroll",
        height: "auto",
        borderRadius: "40px",
    };

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#661d40',
        //backgroundColor: ' #8d949e',
        '&:hover': {
            backgroundColor: '#661d40',
            //backgroundColor: ' #8d949e',
        },
        borderRadius: "40px",

    }));

    const [state, setState] = useState({
        solicitudesCiudadano: [],
        tiposPeticion: [],
        dependencias: [],
        solicitudSeleccionada: {},
        fuentes: [],
        openPeticion: false,
        openEditar: false,
        openEliminar: false,

    }
    );

    const [loader, setLoader] = useState({
        loading: false
    });

    const { id } = useParams();

    const [openModal, setopenModal] = useState(false)

    const { showNotification } = useContext(NotificacionContext)

    const [formularioState, setFormulario] = useState({
        ciudadano_id: '',
        observaciones: '',
        fuente_solicitud_id: '',
        fecha_audiencia: '',
        numero_gira: '',
        lugar_gira: ''
    });

    const [formularioPeticionState, setFormularioPeticion] = useState({
        tipo_peticion_id: '',
        dependencia_id: '',
        atencion_inmediata: true,
        peticion: '',
    });

    const [errors, setErrors] = useState({});

    const openModalPeticion = (data) => {
        setState({ ...state, openPeticion: true, solicitudSeleccionada: data });
    }
    const openModalEditar = (data) => {
        setState({ ...state, openEditar: true, solicitudSeleccionada: data });
    }
    const openModalEliminar = (data) => {
        setState({ ...state, openEliminar: true, solicitudSeleccionada: data });
    }
    const handleClosePeticion = () => {
        setState({ ...state, openPeticion: false });
        limpiarFormularioPeticion();
    }
    const handleCloseEditar = () => {
        setState({ ...state, openEditar: false });
    }
    const handleCloseEliminar = () => {
        setState({ ...state, openEliminar: false });
    }

    // Generar Solicitudes

    const handleFecha = (e) => {
        if (e === null) {
            setFormulario({ ...formularioState, fecha_audiencia: null })

        } else {
            const selectedDate = dayjs(e.$d);
            setFormulario({ ...formularioState, fecha_audiencia: selectedDate.format('YYYY-MM-DD') })
        }
    }

    const handleFuente = (e) => {
        setFormulario({ ...formularioState, fuente_solicitud_id: e.target.value })
    }

    // Editar Solicitudes

    const handleEditarFecha = (e) => {
        if (e === null) {
            setState({ ...state, solicitudSeleccionada: { ...state.solicitudSeleccionada, fecha_audiencia: null } })
        } else {
            const selectedDate = dayjs(e.$d);
            setState({ ...state, solicitudSeleccionada: { ...state.solicitudSeleccionada, fecha_audiencia: selectedDate.format('YYYY-MM-DD') } })
        }
    }

    const handleEditarFuente = (e) => {
        setState({ ...state, solicitudSeleccionada: { ...state.solicitudSeleccionada, fuente_solicitud_id: e.target.value } })
    }

    // Generar Peticiones
    const handleTipoPeticiones = (e) => {
        setFormularioPeticion((formularioPeticionState) => ({ ...formularioPeticionState, tipo_peticion_id: e ? e.id : null, }));
    }

    const handleDependencias = (e) => {
        setFormularioPeticion((formularioPeticionState) => ({ ...formularioPeticionState, dependencia_id: e ? e.id : null, }));
    }

    const handlePrioridad = (e) => {
        setFormularioPeticion({ ...formularioPeticionState, atencion_inmediata: e.target.value })
    }


    const columns = [

        {
            name: "folio",
            label: "FOLIO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let folio = state.solicitudesCiudadano[dataIndex].folio;
                    return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{folio}</div>)
                }
            }
        },
        {
            name: "ciudadano",
            label: "CIUDADANO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let nombre = state.solicitudesCiudadano[dataIndex].ciudadano;
                    return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "fecha_creacion",
            label: "FECHA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let fecha = moment(state.solicitudesCiudadano[dataIndex].fecha_creacion,'DD-MM-YYYY');
                    return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{fecha.format('DD-MM-YYYY')}</div>)
                }
            }
        },
        {
            name: "estado",
            label: "ESTADO",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let estado = state.solicitudesCiudadano[dataIndex].estatus_solicitud;
                    return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{estado}</div>)
                }
            }
        },
        {
            name: "fuente",
            label: "FUENTE",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let fuente = state.solicitudesCiudadano[dataIndex].fuente_solicitud;
                    return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{fuente}</div>)
                }
            }
        },
        {
            name: "numero_gira",
            label: "N° GIRA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let gira = state.solicitudesCiudadano[dataIndex].numero_gira;
                    if (gira !== null) {
                        return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{gira}</div>)
                    } else {
                        return (<div className='d-flex align-items-center justify-content-center' style={{ height: '95px' }}>-</div>)
                    }
                }
            }
        },
        {
            name: "lugar_gira",
            label: "LUGAR GIRA",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex) => {
                    let lugar = state.solicitudesCiudadano[dataIndex].lugar_gira;
                    if (lugar !== null) {
                        return (<div className='d-flex align-items-center' style={{ height: '95px' }}>{lugar}</div>)
                    } else {
                        return (<div className='d-flex align-items-center justify-content-center' style={{ height: '95px' }}>-</div>)
                    }
                }
            }
        },
        {
            name: "acciones",
            label: "ACCIONES",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>
                        <div style={{ height: '60px' }} className="text-decoration-underline d-flex align-items-center" >
                            <Tooltip title="">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openModalPeticion(state.solicitudesCiudadano[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><FaHandsHelping /></IconUI>

                                </div>
                            </Tooltip>
                            <Tooltip title="EDITAR SOLICITUD">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openModalEditar(state.solicitudesCiudadano[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineEdit /></IconUI>

                                </div>
                            </Tooltip>
                            <Tooltip title="ELIMINAR SOLICITUD">
                                <div style={{ height: 30, width: 30, zIndex: 0, }} className='cta cta--primary cta--icon icon_btn'
                                    onClick={
                                        () => {
                                            openModalEliminar(state.solicitudesCiudadano[dataIndex])
                                        }}
                                >
                                    <IconUI size={'20px'} color={'black'}><AiOutlineDelete /></IconUI>

                                </div>
                            </Tooltip>
                        </div>
                    </>
                )
            }
        },


    ];

    const validarFormulario = () => {
        let newErrors = {};
        if (!formularioState.fuente_solicitud_id) { newErrors.fuente = "Este Campo es requerido" }
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const validarFormularioPeticion = () => {
        let newErrors = {};
        if (!formularioPeticionState.tipo_peticion_id) { newErrors.tipo = "Este Campo es requerido" }
        if (!formularioPeticionState.dependencia_id) { newErrors.dependencia = "Este Campo es requerido" }
        if (!formularioPeticionState.peticion) { newErrors.peticion = "Este Campo es requerido" }
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const validarEditar = () => {
        let newErrors = {};
        if (!state.solicitudSeleccionada.fuente_solicitud_id) { newErrors.fuente = "Este Campo es requerido" }
        // if (!formularioState.fecha) { newErrors.fecha = "Este Campo es requerido" }
        // if (!formularioState.noFirmantes) { newErrors.noFirmantes = "Este Campo es requerido" }
        // if (!formularioState.referencia) { newErrors.referencia = "Este Campo es requerido" }
        // if (!formularioState.obsevaciones) { newErrors.obsevaciones = "Este Campo es requerido" }
        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    }

    const limpiarFormulario = () => {

        setFormulario({
            ...formularioState,
            ciudadano_id: '',
            observaciones: '',
            fuente_solicitud_id: '',
            fecha_audiencia: '',
            numero_gira: '',
            lugar_gira: ''
        })

    }

    const limpiarFormularioPeticion = () => {

        setFormularioPeticion({
            ...formularioPeticionState,
            tipo_peticion_id: '',
            dependencia_id: '',
            atencion_inmediata: true,
            peticion: '',
        })

    }

    const crearPeticion = async () => {
        setLoader({ ...loader, loading: true })
        if (validarFormularioPeticion()) {
            const peticion = await services({
                method: 'POST', service: 'peticiones/crear', body: {
                    solicitud_id: state.solicitudSeleccionada.id,
                    tipo_peticion_id: formularioPeticionState.tipo_peticion_id,
                    dependencia_id: formularioPeticionState.dependencia_id,
                    atencion_inmediata: formularioPeticionState.atencion_inmediata,
                    peticion: formularioPeticionState.peticion,
                }
            })
            if (peticion.status === 200) {
                let solicitudesCiudadano = await services({ method: 'POST', service: `solicitudes/solicitudesCiudadano`, body: { id_ciudadano: id } });
                if (solicitudesCiudadano.status === 200) {
                    showNotification('success', 'Petición', 'Creada con Éxito', null, 4);
                    limpiarFormularioPeticion();
                    setState({ ...state, solicitudesCiudadano: solicitudesCiudadano.data.data, openPeticion: false });
                    setLoader({ ...loader, loading: false });
                    setPeticion(peticion.data.folio);
                    goTo(`/peticionesCiudadano/${id}`);
                }else{
                    setState({ ...state, openPeticion: false });
                    setLoader({ ...loader, loading: false });
                    limpiarFormularioPeticion()
                    showNotification('error', 'Ups... Algo sucedió', 'Error al Cargar las Solicitudes', null, 4);    
                }
            } else {
                setState({ ...state, openPeticion: false });
                setLoader({ ...loader, loading: false })
                limpiarFormularioPeticion()
                showNotification('error', 'Ups... Algo sucedió', 'Error al Crear Petición', null, 4);
            }
        } else {
            showNotification('error', 'Ups... ', 'Por favor llena los campos marcados', null, 4);
            setLoader({ ...loader, loading: false })
        }
    }

    const crearSolicitud = async () => {
        // setLoader({ loading: true })
        if (validarFormulario()) {
            const solicitud = await services({
                method: 'POST', service: 'solicitudes/crear', body: {
                    ciudadano_id: id,
                    fuente_solicitud_id: formularioState.fuente_solicitud_id,
                    fecha_audiencia: formularioState.fecha_audiencia,
                    numero_gira: formularioState.numero_gira,
                    lugar_gira: formularioState.lugar_gira,
                    observaciones: formularioState.observaciones
                }
            })
            if (solicitud.status === 200) {
                let solicitudesCiudadano = await services({ method: 'POST', service: `solicitudes/solicitudesCiudadano`, body: { id_ciudadano: id } });
                setState({ ...state, solicitudesCiudadano: '' })
                if (solicitudesCiudadano.status === 200) {
                    setopenModal(false);
                    setState({ ...state, solicitudesCiudadano: solicitudesCiudadano.data.data })
                    showNotification('success', 'Solicitud Creada con Éxito', 'Tu folio es:'+ solicitud.data.folio, null);
                    limpiarFormulario()
                    setLoader({ loading: false })
                } else {
                    setLoader({ loading: false })
                    setopenModal(false);
                    limpiarFormulario()
                    showNotification('error', 'Ups... Algo sucedió', 'Error al Generar la Solicitud', null, 4);
                }
            } else {
                setopenModal(false);
                setLoader({ loading: false })
                limpiarFormulario()
                showNotification('error', 'ups... Algo sucedió', 'Error al Generar la Solicitud', null, 4);
            }

        } else {

            showNotification('error', 'Ups... ', 'Por favor llena los campos', null, 4);
            setLoader({ loading: false })

        }
    }

    const editarSolicitud = async () => {
        if (validarEditar) {
            setLoader({ loading: true })
            const editarSolicitud = await services({
                method: 'POST', service: `solicitudes/editarSolicitud`, body: {
                    id: state.solicitudSeleccionada.id,
                    ciudadano_id: id,
                    fuente_solicitud_id: state.solicitudSeleccionada.fuente_solicitud_id,
                    fecha_audiencia: state.solicitudSeleccionada.fecha_audiencia,
                    estatus_solicitud_id: state.solicitudSeleccionada.estatus_solicitud_id,
                    numero_gira: state.solicitudSeleccionada.numero_gira,
                    lugar_gira: state.solicitudSeleccionada.lugar_gira?.toUpperCase(),
                    observaciones: state.solicitudSeleccionada.observaciones?.toUpperCase()
                }
            })
            if (editarSolicitud.status === 200) {
                let solicitudesCiudadano = await services({ method: 'POST', service: `solicitudes/solicitudesCiudadano`, body: { id_ciudadano: id } });
                setState({ ...state, solicitudesCiudadano: '' })
                if (solicitudesCiudadano.status === 200) {
                    setState({ ...state, solicitudesCiudadano: solicitudesCiudadano.data.data, openEditar: false })
                    showNotification('success', 'Solicitud', 'Editada con Éxito', null, 4);
                    setLoader({ loading: false })
                } else {
                    setState({ ...state, openEditar: false })
                    setLoader({ loading: false })
                    showNotification('error', 'ups... Algo sucedió', 'Error al Editar la Solicitud', null, 4);
                }
            } else {
                setState({ ...state, openEditar: false })
                setLoader({ loading: false })
                showNotification('error', 'Ups...', 'Error al editar Solicitud', null, 4);
            }
        } else {
            showNotification('error', 'Ups...', 'Error favor de llenar los campos', null, 4);
        }
    }

    const eliminarSolicitud = async () => {
        setLoader(true)
        const eliminarSolicitud = await services({ method: 'POST', service: `solicitudes/eliminarSolicitud`, body: { id: state.solicitudSeleccionada.id } })
        if (eliminarSolicitud.status === 200) {
            let solicitudesCiudadano = await services({ method: 'POST', service: `solicitudes/solicitudesCiudadano`, body: { id_ciudadano: id } })
            if (solicitudesCiudadano.status === 200) {
                setState({ ...state, solicitudesCiudadano: solicitudesCiudadano.data.data, openEliminar: false })
                showNotification('success', 'Solicitud Eliminada con Éxito', '', null, 4);
                setLoader(false)
            } else {
                setLoader(false)
                setState({ ...state, openEliminar: false })
                showNotification('error', 'Ups...', 'Error al Eliminar el Solicitud', null, 4);
            }
        } else if (eliminarSolicitud.status === 409) {
            setLoader(false)
            setState({ ...state, openEliminar: false })
            showNotification('error', 'Ups...', 'Error la solicitud seleccionada aun tiene peticiones asignadas', null, 4);
        } else {
            setLoader(false)
            setState({ ...state, openEliminar: false })
            showNotification('error', 'Ups...', 'Error al Eliminar el Solicitud', null, 4);
        }
    }

    const mostrarSolicitud = async () => {
        setLoader({ ...loader, loading: true });
        const solicitudesCiudadano = await services({ method: 'POST', service: `solicitudes/solicitudesCiudadano`, body: { id_ciudadano: id } });
        const fuentes = await services({ method: "GET", service: "catFuentesSolicitudes/listar", body: null });
        const tipoPeticiones = await services({ method: "GET", service: "catTipoPeticiones/listarPeticiones", body: null });
        const dependecias = await services({ method: "GET", service: "dependencias/listar", body: null });

        if (solicitudesCiudadano.status === 200 && fuentes.status === 200 && tipoPeticiones.status === 200 && dependecias.status === 200) {
            setLoader({ ...loader, loading: false });
            setState({ ...state, solicitudesCiudadano: solicitudesCiudadano.data.data, fuentes: fuentes.data.data, tiposPeticion: tipoPeticiones.data.data, dependencias: dependecias.data.data })
        } else {
            setLoader({ ...loader, loading: false });
            showNotification('error', 'Ups...', 'Error al cargar las solicitudes inténtelo mas tarde', null, 4);
        }
    }


    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            mostrarSolicitud()
        }

        return () => {
            isMounted = false
        }
        // eslint-disable-next-line 
    }, [])

    return (
        <>
            <div className=' justify-content-center align-items-start py-4'>
                {loader.loading &&
                    <LoaderComponent />
                }

                <div className='container'>
                    <Grid sx={{ marginTop: 3 }} container justifyContent="space-between" alignItems="center" >

                        <Grid item  >
                            <ColorButton startIcon={<IoMdArrowRoundBack />} onClick={() => { goTo(-1) }}>
                                ATRÁS
                            </ColorButton>
                        </Grid>

                        <Grid item >
                            <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() => { setopenModal(true) }}>
                                CREAR SOLICITUD
                            </ColorButton>
                        </Grid>

                    </Grid>
                </div>
                <DataTable
                    data={state.solicitudesCiudadano}
                    title={`TABLA DE SOLICITUDES CIUDADANO`}
                    columns={columns}
                />

                {/*Modal para crear */}

                <Modal
                    open={openModal}
                    onClose={() => { limpiarFormulario(); setopenModal(false) }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            CREAR SOLICITUD
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="FUENTES"
                                    variant="outlined"
                                    select
                                    error={!!errors.fuente}
                                    helperText={errors.fuente || ''}
                                    onChange={handleFuente}
                                    value={formularioState.fuente_solicitud_id}
                                >
                                    {state.fuentes.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.fuente_solicitud}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {formularioState.fuente_solicitud_id === 1 ?
                                <Grid item xs={12}>
                                    <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                        <DatePicker
                                            label="FECHA DE AUDIENCIA"
                                            slotProps={{ textField: { size: 'small' } }}
                                            sx={{ width: 1 }}
                                            // slotProps={{ textField: { helperText: 'Please fill this field' } }}
                                            // defaultValue={today}
                                            format="DD/MM/YYYY"
                                            views={['year', 'month', 'day']}
                                            onChange={handleFecha}
                                            // disablePast
                                        />
                                    </LocalizationProvider>
                                </Grid> : <></>
                            }

                            {
                                formularioState.fuente_solicitud_id === 3 ?
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label="N° DE GIRA"
                                            value={formularioState.numero_gira}
                                            error={!!errors.numero_gira}
                                            helperText={errors.numero_gira || ''}
                                            onChange={({ target }) => setFormulario({ ...formularioState, numero_gira: target.value })}
                                            onKeyPress={onlyNumber}
                                        />
                                    </Grid>
                                    :
                                    <></>
                            }
                            {
                                formularioState.fuente_solicitud_id === 3 ?
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            size='small'
                                            label="LUGAR DE GIRA"
                                            value={formularioState.lugar_gira}
                                            error={!!errors.lugar_gira}
                                            helperText={errors.lugar_gira || ''}
                                            onChange={({ target }) => setFormulario({ ...formularioState, lugar_gira: target.value.toUpperCase() })}
                                        />
                                    </Grid>
                                    :
                                    <></>
                            }

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label="OBSERVACIONES"
                                    value={formularioState.observaciones}
                                    error={!!errors.observaciones}
                                    helperText={errors.observaciones || ''}
                                    onChange={({ target }) => setFormulario({ ...formularioState, observaciones: target.value.toUpperCase() })}
                                />
                            </Grid>


                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    crearSolicitud();
                                }}
                                >
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Editar */}

                <Modal
                    open={state.openEditar}
                    onClose={() => handleCloseEditar()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            EDITAR SOLICITUD
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="FUENTES"
                                    variant="outlined"
                                    select
                                    error={!!errors.fuente}
                                    helperText={errors.fuente || ''}
                                    onChange={handleEditarFuente}
                                    value={state.solicitudSeleccionada.fuente_solicitud_id}
                                >
                                    {state.fuentes.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.fuente_solicitud}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {state.solicitudSeleccionada.fuente_solicitud_id === 1 ?
                                <Grid item xs={12}>
                                    <LocalizationProvider adapterLocale='es' dateAdapter={AdapterDayjs} >
                                        <DatePicker
                                            label="FECHA DE  AUDIENCIA"
                                            slotProps={{ textField: { size: 'small' } }}
                                            sx={{ width: 1 }}
                                            value={state.solicitudSeleccionada.fecha_audiencia ? dayjs(state.solicitudSeleccionada.fecha_audiencia) : null}
                                            format="DD/MM/YYYY"
                                            views={['year', 'month', 'day']}
                                            onChange={handleEditarFecha}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                :
                                <></>
                            }
                            {state.solicitudSeleccionada.fuente_solicitud_id === 3 ?
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label="N° DE GIRA"
                                        value={state.solicitudSeleccionada.numero_gira}
                                        error={!!errors.numero_gira}
                                        helperText={errors.numero_gira || ''}
                                        onChange={({ target }) => setState({ ...state, solicitudSeleccionada: { ...state.solicitudSeleccionada, numero_gira: target.value } })}
                                        onKeyPress={onlyNumber}
                                        disabled={state.solicitudSeleccionada.fuente_solicitud_id === 3 ? false : true}
                                    />
                                </Grid>
                                :
                                <></>
                            }
                            {state.solicitudSeleccionada.fuente_solicitud_id === 3 ?
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        label="LUGAR DE GIRA"
                                        value={state.solicitudSeleccionada.lugar_gira}
                                        error={!!errors.lugar_gira}
                                        helperText={errors.lugar_gira || ''}
                                        onChange={({ target }) => setState({ ...state, solicitudSeleccionada: { ...state.solicitudSeleccionada, lugar_gira: target.value.toUpperCase() } })}
                                        disabled={state.solicitudSeleccionada.fuente_solicitud_id === 3 ? false : true}
                                    />
                                </Grid>
                                :
                                <></>
                            }
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    label="OBSERVACIONES"
                                    value={state.solicitudSeleccionada.observaciones}
                                    error={!!errors.observaciones}
                                    helperText={errors.observaciones || ''}
                                    onChange={({ target }) => setState({ ...state, solicitudSeleccionada: { ...state.solicitudSeleccionada, observaciones: target.value } })
                                    }
                                // 

                                />
                            </Grid>


                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    editarSolicitud()
                                }}
                                >
                                    EDITAR
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/*Modal para Eliminar */}

                <Modal
                    open={state.openEliminar}
                    onClose={handleCloseEliminar}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs text-center' sx={style}>
                        <h3 className='TitulosProgramas mb-3'>
                            ¿ESTÁ SEGURO DE  ELIMINAR LA SOLICITUD CON FOLIO?
                        </h3>

                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <h4>{state.solicitudSeleccionada.folio}</h4>
                            </Grid>
                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    eliminarSolicitud();

                                }}

                                >
                                    ELIMINAR
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>

                {/* Modal para crear peticion */}

                <Modal
                    open={state.openPeticion}
                    onClose={() => handleClosePeticion()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className='contenedorModalUrs' sx={style}>
                        <h3 className='TitulosProgramas'>
                            CREAR PETICIÓN
                        </h3>
                        <Grid
                            container
                            columns={12}
                            spacing={3}
                        >
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={state.tiposPeticion|| []}
                                    getOptionLabel={(option) => option.tipo_peticion}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={state.tiposPeticion.find(option => option.id === formularioPeticionState.tipo_peticion_id) || null}
                                    onChange={(event, newValue) => handleTipoPeticiones(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="TIPO DE PETICIÓN"
                                            error={!!errors.tipo}
                                            helperText={errors.tipo|| ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    size='small'
                                    label="PETICIÓN"
                                    error={!!errors.peticion}
                                    rows={4}
                                    helperText={errors.peticion || ''}
                                    value={formularioPeticionState.peticion}
                                    onChange={({ target }) => setFormularioPeticion({ ...formularioPeticionState, peticion: target.value })}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Autocomplete
                                    options={state.dependencias|| []}
                                    getOptionLabel={(option) => option.dependencia}
                                    fullWidth
                                    size='small'
                                    id="textFile-area"
                                    sx={{
                                        borderRadius: "30px", // Aplicamos borderRadius solo a TextField
                                    }}
                                    value={state.dependencias.find(option => option.id === formularioPeticionState.dependencia_id) || null}
                                    onChange={(event, newValue) => handleDependencias(newValue)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            variant="outlined"
                                            label="DEPENDENCIAS"
                                            error={!!errors.dependencia}
                                            helperText={errors.dependencia|| ''}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    sx={{ borderRadius: "30px" }}
                                    fullWidth
                                    size='small'
                                    id="textFile-Fuentes"
                                    label="PRIORIDAD"
                                    variant="outlined"
                                    select
                                    error={!!errors.atencion}
                                    helperText={errors.atencion || ''}
                                    onChange={handlePrioridad}
                                    value={formularioPeticionState.atencion_inmediata}
                                >
                                    <MenuItem value={true}>
                                        INMEDIATA
                                    </MenuItem>
                                    <MenuItem value={false}>
                                        ALTA
                                    </MenuItem>
                                </TextField>
                            </Grid>




                            <Grid marginLeft="auto" item >
                                <ColorButton onClick={() => {
                                    crearPeticion();
                                }}
                                >
                                    Guardar
                                </ColorButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>


            </div>
        </>

    )
}





export default SolicitudCiudadano;