import { useContext, useRef } from "react";
import { useState } from "react";
import { services } from "../services/api";
import LoaderComponent from "../components/admin/LoaderComponent";
import { NotificacionContext } from "../context/notificacion_context";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

import { TextField, MenuItem, useMediaQuery, useTheme } from "@mui/material";

import { onlyNumber } from "../utils/inputsRules";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const CrearPeticionView = () => {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState("");
  const [state, setState] = useState({
    step: 0,
  });
  const [formularioState, setFormulario] = useState({
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    sexo: "",
    edad: "",
    ine: "",
    curp: "",
    telefono: "",
    email: "",
    tipo_persona: "",
    ocupacion: "",

    cp: "",
    numero_ext: "",
    calle: "",
    colonia: "",
    localidad: "",
    municipio: "",
    estado: "",
    peticion: "",
  });

  const [loader, setLoader] = useState({
    loading: false,
  });
  const { showNotification } = useContext(NotificacionContext);

  const [errors, setErrors] = useState({});

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNombre = (e) => {
    setFormulario({ ...formularioState, nombre: e.target.value });
  };
  const handleApellidoPaterno = (e) => {
    setFormulario({ ...formularioState, apellido_paterno: e.target.value });
  };
  const handleApellidoMaterno = (e) => {
    setFormulario({ ...formularioState, apellido_materno: e.target.value });
  };
  const handleSexo = (e) => {
    setFormulario({ ...formularioState, sexo: e.target.value });
  };
  const handleEdad = (e) => {
    setFormulario({ ...formularioState, edad: e.target.value });
  };
  const handleIne = (e) => {
    setFormulario({ ...formularioState, ine: e.target.value });
  };
  const handleCurp = (e) => {
    setFormulario({ ...formularioState, curp: e.target.value });
  };
  const handleTelefono = (e) => {
    setFormulario({ ...formularioState, telefono: e.target.value });
  };
  const handleEmail = (e) => {
    setFormulario({ ...formularioState, email: e.target.value });
  };
  const handleTipoPersona = (e) => {
    setFormulario({ ...formularioState, tipo_persona: e.target.value });
  };
  const handleOcupacion = (e) => {
    setFormulario({ ...formularioState, ocupacion: e.target.value });
  };
  const handleCP = (e) => {
    setFormulario({ ...formularioState, cp: e.target.value });
  };
  const handleNoExterior = (e) => {
    setFormulario({ ...formularioState, numero_ext: e.target.value });
  };
  const handleCalle = (e) => {
    setFormulario({ ...formularioState, calle: e.target.value });
  };
  const handleColonia = (e) => {
    setFormulario({ ...formularioState, colonia: e.target.value });
  };
  const handleLocalidad = (e) => {
    setFormulario({ ...formularioState, localidad: e.target.value });
  };
  const handleMunicipio = (e) => {
    setFormulario({ ...formularioState, municipio: e.target.value });
  };
  const handleEstado = (e) => {
    setFormulario({ ...formularioState, estado: e.target.value });
  };
  const handlePeticion = (e) => {
    setFormulario({ ...formularioState, peticion: e.target.value });
  };

  const validarFormulario = () => {
    let newErrors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const regexCurp =
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
      const regexIne = /^.{13}$/;


    if (!formularioState.nombre) {
      newErrors.nombre = "Este Campo es Requerido";
    }
    if (!formularioState.apellido_paterno) {
      newErrors.apellidoPaterno = "Este Campo es Requerido";
    }
    if (!formularioState.calle) {
      newErrors.calle = "Este Campo es Requerido";
    }
    if (!formularioState.municipio) {
      newErrors.municipio = "Este Campo es Requerido";
    }
    if (!formularioState.estado) {
      newErrors.estado = "Este Campo es Requerido";
    }
    if (!formularioState.peticion) {
      newErrors.peticion = "Este Campo es Requerido";
    }
    if (
      formularioState.telefono !== "" &&
      formularioState.telefono.length < 10
    ) {
      newErrors.telefono = "Favor de poner un número válido";
    }
    if (formularioState.curp !== "" && formularioState.curp.length < 18) {
      let valido = formularioState.curp.match(regexCurp);
      if (!valido) {
        newErrors.curp = "Favor de poner una curp válida";
      }
    } else if (formularioState.curp === "") {
      newErrors.curp = "Este Campo es Requerido";
    }
    if (formularioState.email !== "") {
      let valido = formularioState.email.match(regexEmail);
      if (!valido) {
        newErrors.email = "Ingrese un email válido";
      }
    } else if (formularioState.email === "") {
      newErrors.email = "Este Campo es Requerido";
    }
    if (formularioState.ine !== "" && formularioState.ine.length < 13) {
      let valido = formularioState.ine.match(regexIne);
      if (!valido) {
        newErrors.ine = "Favor de poner un número válido";
      }
    } 
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCrearPeticion = async () => {
    // setState({ ...state, message: "", errorNombre: "", errorApellido_paterno: "", errorFolio: "" })
    if (validarFormulario()) {
      setLoader({ ...loader, loading: true });
      const formData = new FormData();

      formData.append("nombre", formularioState.nombre);
      formData.append("apellido_paterno", formularioState.apellido_paterno);
      formData.append("apellido_materno", formularioState.apellido_materno);
      formData.append("sexo", formularioState.sexo);
      formData.append("edad", formularioState.edad);
      formData.append("ine", formularioState.ine);
      formData.append("curp", formularioState.curp);
      formData.append("telefono", formularioState.telefono);
      formData.append("email", formularioState.email);
      formData.append("tipo_persona", formularioState.tipo_persona);
      formData.append("ocupacion", formularioState.ocupacion);
      formData.append("cp", formularioState.cp);
      formData.append("numero_ext", formularioState.numero_ext);
      formData.append("calle", formularioState.calle);
      formData.append("colonia", formularioState.colonia);
      formData.append("localidad", formularioState.localidad);
      formData.append("municipio", formularioState.municipio);
      formData.append("estado", formularioState.estado);
      formData.append("peticion", formularioState.peticion);
      formData.append("archivo", selectedFile);

      let response = await services({
        method: "POST",
        service: `enLinea/crearPeticion`,
        body: formData,
      });
      console.log(response.data, "data");
      if (response.status === 200) {
        setLoader({ ...loader, loading: false });
        Swal.fire({
          title:
            "Solicitud enviada con éxito, en breve se le informará el trámite de la misma.",
          confirmButtonText: "Aceptar",
        });
        setState({
          ...state,
          message: "",
          errorNombre: "",
          errorApellido_paterno: "",
          errorFolio: "",
        });
        limpiarFormulario();
        setTimeout(() => {
          navigate("/aciudadana");
        }, 5000);
        // showNotification('success', 'Solicitud Enviada', 'Se creo con éxito la solicitud', null, 5);
      } else {
        setLoader({ ...loader, loading: false });
        if (response.status !== 409) {
          setState({
            ...state,
            message: "Información Inválida",
            errorNombre: response.data.message.nombre,
            errorApellido_paterno: response.data.message.apellido_paterno,
            errorFolio: response.data.message.folio,
          });
          showNotification("error", "Error", "Información Inválida", null, 4);
        } else {
          setState({
            ...state,
            message: response.data.message,
            errorNombre: "",
            errorApellido_paterno: "",
            errorFolio: "",
          });
        }
      }
    } else {
      showNotification(
        "error",
        "Ups...Error ",
        "Por favor llena todos los campos",
        null,
        4
      );
      setLoader({ ...loader, loading: false });
    }
  };

  const limpiarFormulario = () => {
    setFormulario({
      nombre: "",
      apellido_paterno: "",
      apellido_materno: "",
      sexo: "",
      edad: "",
      ine: "",
      curp: "",
      telefono: "",
      email: "",
      tipo_persona: "",
      ocupacion: "",

      cp: "",
      numero_ext: "",
      calle: "",
      colonia: "",
      localidad: "",
      municipio: "",
      estado: "",
      peticion: "",
    });
    setSelectedFile("");
  };
  const handleRemoveStep = () => {
    let newStep = state.step;
    if (state.step !== 0) {
      setState({ ...state, step: newStep - 1 });
    }
  };
  const handleAddStep = () => {
    let newStep = state.step;
    console.log(newStep, "new Step");
    if (state.step !== 2) {
      console.log(newStep + 1, "new Step");
      setState({ ...state, step: newStep + 1 });
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const fileInputRef = useRef(null);

  return (
    <div className="container">
      {loader.loading && <LoaderComponent />}
      <div className="text-big pt-3">ATENCIÓN CIUDADANA MICHOACÁN</div>

      <div className="card card--GuindaSFA card--rounded mt-3">
        <div className=" d-flex justify-content-center ">
          <div
            className={
              state.solicitud
                ? "ms-4 cta-line"
                : " cta-line-active ms-4 cta-line"
            }
          >
            CREAR PETICIÓN
          </div>
        </div>
      </div>
      <div className="mt-5 card card--rounded ">
        <div className="my-3">
          Para generar una petición, por favor ingresa, la siguiente
          información.
        </div>
        <Grid container spacing={3}>
          {state.step === 0 && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="NOMBRE *"
                  variant="outlined"
                  error={!!errors.nombre}
                  helperText={errors.nombre || ""}
                  value={formularioState.nombre}
                  onChange={handleNombre}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="A.PATERNO *"
                  variant="outlined"
                  error={!!errors.apellidoPaterno}
                  helperText={errors.apellidoPaterno || ""}
                  value={formularioState.apellido_paterno}
                  onChange={handleApellidoPaterno}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="A.MATERNO"
                  variant="outlined"
                  value={formularioState.apellido_materno}
                  onChange={handleApellidoMaterno}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-Titulo"
                  label="Sexo"
                  variant="outlined"
                  select
                  onChange={handleSexo}
                  value={formularioState.sexo}
                >
                  <MenuItem key={0} value={"Femenino"}>
                    Feminino
                  </MenuItem>
                  <MenuItem key={1} value={"Masculino"}>
                    Masculino
                  </MenuItem>
                  <MenuItem key={2} value={"LGTBIQ+"}>
                    LGTBIQ+
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="EDAD"
                  variant="outlined"
                  value={formularioState.edad}
                  onChange={handleEdad}
                  onKeyPress={onlyNumber}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="CURP *"
                  variant="outlined"
                  error={!!errors.curp}
                  helperText={errors.curp || ""}
                  value={formularioState.curp}
                  onChange={handleCurp}
                  inputProps={{ maxLength: 18 }}
                />
              </Grid>
            </>
          )}

          {state.step === 1 && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="INE"
                  variant="outlined"
                  error={!!errors.ine}
                  helperText={errors.ine || ""}
                  value={formularioState.ine}
                  onChange={handleIne}
                  inputProps={{ maxLength: 13 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="TELÉFONO"
                  error={!!errors.telefono}
                  helperText={errors.telefono || ""}
                  variant="outlined"
                  value={formularioState.telefono}
                  onChange={handleTelefono}
                  onKeyPress={onlyNumber}
                  inputProps={{ maxLength: 10 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="EMAIL *"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors.email || ""}
                  value={formularioState.email}
                  onChange={handleEmail}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="TIPO PERSONA"
                  variant="outlined"
                  select
                  value={formularioState.tipo_persona}
                  onChange={handleTipoPersona}
                >
                  <MenuItem key={0} value={"CIUDADANO"}>
                    CIUDADANO
                  </MenuItem>
                  <MenuItem key={1} value={"ASOCIACIÓN CIVIL"}>
                    ASOCIACIÓN CIVIL
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="OCUPACION"
                  variant="outlined"
                  value={formularioState.ocupacion}
                  onChange={handleOcupacion}
                />
              </Grid>
            </>
          )}

          {state.step === 2 && (
            <>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="CP"
                  variant="outlined"
                  value={formularioState.cp}
                  onChange={handleCP}
                  onKeyPress={onlyNumber}
                  inputProps={{ maxLength: 5 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="NO. EXTERIOR"
                  variant="outlined"
                  value={formularioState.numero_ext}
                  onChange={handleNoExterior}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="CALLE *"
                  variant="outlined"
                  error={!!errors.calle}
                  helperText={errors.calle || ""}
                  value={formularioState.calle}
                  onChange={handleCalle}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="COLONIA"
                  variant="outlined"
                  value={formularioState.colonia}
                  onChange={handleColonia}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="LOCALIDAD"
                  variant="outlined"
                  value={formularioState.localidad}
                  onChange={handleLocalidad}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="MUNICIPIO *"
                  variant="outlined"
                  error={!!errors.municipio}
                  helperText={errors.municipio || ""}
                  value={formularioState.municipio}
                  onChange={handleMunicipio}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="ESTADO *"
                  variant="outlined"
                  error={!!errors.estado}
                  helperText={errors.estado || ""}
                  value={formularioState.estado}
                  onChange={handleEstado}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ borderRadius: "30px" }}
                  fullWidth
                  size="small"
                  id="textFile-nombre"
                  label="PETICION *"
                  variant="outlined"
                  error={!!errors.peticion}
                  helperText={errors.peticion || ""}
                  value={formularioState.peticion}
                  onChange={handlePeticion}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  accept="*/*"
                  style={{ display: "none" }}
                  id="file-upload-input"
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                <label htmlFor="file-upload-input">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    Subir Archivo
                  </Button>
                </label>
                {selectedFile && (
                  <div>
                    <p>Nombre del archivo: {selectedFile.name}</p>
                  </div>
                )}
              </Grid>
            </>
          )}
        </Grid>

        <div className="my-3">
          {errors.nombre && (
            <div style={{ color: "red", fontSize: "12px" }}>
              Nombre: {errors.nombre}
            </div>
          )}
          {errors.apellidoPaterno && (
            <div style={{ color: "red", fontSize: "12px" }}>
              Apellido Paterno: {errors.apellidoPaterno}
            </div>
          )}
          {errors.curp && (
            <div style={{ color: "red", fontSize: "12px" }}>
              Curp: {errors.curp}
            </div>
          )}
          {errors.email && (
            <div style={{ color: "red", fontSize: "12px" }}>
              Email: {errors.email}
            </div>
          )}
          {errors.calle && (
            <div style={{ color: "red", fontSize: "12px" }}>
              Calle: {errors.calle}
            </div>
          )}
          {errors.municipio && (
            <div style={{ color: "red", fontSize: "12px" }}>
              Municipio: {errors.municipio}
            </div>
          )}
          {errors.estado && (
            <div style={{ color: "red", fontSize: "12px" }}>
              Estado: {errors.estado}
            </div>
          )}
          {errors.peticion && (
            <div style={{ color: "red", fontSize: "12px" }}>
              Peticion: {errors.peticion}
            </div>
          )}
        </div>

        <div>
          {state.step === 0 ? (
            <div className="d-flex justify-content-end my-3">
              <div
                style={{ width: "100px" }}
                className="cta cta--guinda"
                onClick={handleAddStep}
              >
                Siguiente
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-end my-3">
              <div
                style={{ width: "100px" }}
                className="cta cta--white"
                onClick={handleRemoveStep}
              >
                Atras
              </div>
              {state.step !== 2 && (
                <div
                  style={{ width: "100px" }}
                  className="cta cta--guinda ms-3"
                  onClick={handleAddStep}
                >
                  Siguiente
                </div>
              )}
              {state.step > 1 && (
                <div
                  className="cta cta--guinda ms-2"
                  onClick={handleCrearPeticion}
                >
                  Crear Solicitud
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CrearPeticionView;
